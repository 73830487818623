import { Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import ReactLoader from "../../components/common/loader/ReactLoader";
import { loginSchema } from "../../schemas/validation-schemas";
import axiosInstance from "../../services/axiosInstance";
import TextField from "../common/forms/TextField";
import styles from "./login.module.css";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={`${styles.login_main_div}`}>
      <div className={`${styles.navbar_login}`}></div>
      {loading && <ReactLoader />}
      <div className={`${styles.login_container} container-fluid`}>
        <div className={`${styles.login_main_row} row`}>
          <div className={`${styles.form_col} col-lg-6 col-md-6 col-sm-12`}>
            <h1 className={`${styles.form_title}`}>
              Start Finding <br /> Companion
            </h1>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={async (data, { resetForm }) => {
                setLoading(true);
                try {
                  const response = await axiosInstance.post(
                    "auth/token/login/",
                    data,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  if (response.status === 200) {
                    console.log("resssponse", await response);
                    localStorage.setItem("token", response.data.auth_token);
                    toast.success("Login Successful");
                    setLoading(false);
                    resetForm();
                    navigate("/");
                  }
                } catch (err) {
                  if (err.response.status === 400) {
                    toast.error("Invalid Credentials");
                    setLoading(false);
                    resetForm();
                  }
                }
              }}
              validateOnMount
              validationSchema={loginSchema}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <div className={`${styles.form_div}`}>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <TextField type="email" name="email" label="Email" />
                      <TextField
                        type="password"
                        name="password"
                        label="Password"
                      />

                      <div className={`form-check ${styles.forgot_pass_div} `}>
                        <NavLink to="/forgot-password">
                          <span className={`${styles.forgot_password}`}>
                            Forgot password?
                          </span>
                        </NavLink>
                        {/* <span className={`${styles.forgot_password}`}>Forgot Password?</span> */}
                      </div>
                      {/* <input
                        className={`${styles.submit_btn} form-control`}
                        type="submit"
                        value="Login"
                      /> */}
                      <button
                        className={`btn btn-primary  ${styles.submit_btn}`}
                        type="submit"
                        disabled={!isValid || loading}
                      >
                        Login
                      </button>
                    </form>
                  </div>
                );
              }}
            </Formik>
          </div>

          <div
            className={`${styles.right_col_div} col-lg-6 col-md-6 col-sm-12`}
          >
            <div className={`${styles.right_col_img_div}`}>
              <img
                className={`${styles.right_col_img}`}
                src="/images/login-signup/photo1.jpg"
                alt="group11"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
