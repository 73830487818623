export const interestList = [
  {
    id: 1,
    name: "Rafting",
    imageUrl: "/images/homepage/interest/Rafting.png",
  },
  {
    id: 2,
    name: "Yoga",
    imageUrl: "/images/homepage/interest/Yoga.png",
  },
  {
    id: 3,
    name: "Dancing",
    imageUrl: "/images/homepage/interest/Dancing.png",
  },
  {
    id: 4,
    name: "Partying",
    imageUrl: "/images/homepage/interest/Partying.png",
  },
  {
    id: 5,
    name: "Driving",
    imageUrl: "/images/homepage/interest/Driving.png",
  },
  {
    id: 6,
    name: "Pilgrimage",
    imageUrl: "/images/homepage/interest/Pilgrimage.png",
  },
  {
    id: 7,
    name: "Camping",
    imageUrl: "/images/homepage/interest/Camping.png",
  },
  {
    id: 8,
    name: "Cooking",
    imageUrl: "/images/homepage/interest/Cooking.png",
  },
  {
    id: 9,
    name: "Hiking",
    imageUrl: "/images/homepage/interest/Hiking.png",
  },
  {
    id: 10,
    name: "Biking",
    imageUrl: "/images/homepage/interest/Riding.png",
  },
  {
    id: 11,
    name: "Sight Seeing",
    imageUrl: "/images/homepage/interest/sightseeing.png",
  },
];
