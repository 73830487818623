export const projectList = [
  {
    id: 1,
    name: "1",
    imageUrl: "/images/homepage/project/1.jpg",
  },
  {
    id: 2,
    name: "2",
    imageUrl: "/images/homepage/project/2.jpg",
  },
  {
    id: 3,
    name: "3",
    imageUrl: "/images/homepage/project/3.jpg",
  },
];
