import { Icon } from "@iconify/react";
import React from "react";
import HomeTitle from "../../../pages/common/HomeTitle";
import styles from "./hometrending.module.css";

const HomeTrending = () => {
  return (
    <div className={`${styles.home_trending_section_container} container`}>
      <div className={`${styles.home_trending_section}`}>
        <div className={`${styles.home_trending_title_div}`}>
          <HomeTitle title="Explore" subtitle="Trending Places" />
        </div>

        {/* First Row Image */}

        <div className={`row ${styles.images_div}`}>
          <div
            className={`${styles.img_div_1} col-lg-7 col-md-7 col-sm-12 p-0`}
          >
            <img
              className={`${styles.place_img_1}`}
              src="/images/homepage/Places/1.jpg"
              alt="place"
            />
            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Pokhara</h3>
              <p className={`${styles.hover_para}`}>
                Pokhara is the gateway to the himalayas and the starting point
                for treks. It is a valley cradled amongst mountains and hills
                with meandering rivers. It is a major tourist destination due to
                its panoramic views. Once you enter the vicinity of this city,
                you are greeted with magnificent views of the mountains. The
                Dhaulagiri range and Annapurna range tower majestically over the
                green valley.
              </p>
            </div>
          </div>
          <div
            className={`${styles.img_div_2} col-lg-5 col-md-5 col-sm-12 pr-0`}
          >
            <img
              className={`${styles.place_img_2}`}
              src="/images/homepage/Places/2.jpg"
              alt="place"
            />

            {/* <HoverEffect  /> */}

            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Kathmandu</h3>
              <p className={`${styles.hover_para}`}>
                Kathmandu is a city for all types of visitors, whether they are
                looking for nature, adventure, animals, food, or culture. It is,
                in fact, a great city to visit not only for what it offers but
                also for the places around it, from its temples to its mountains
                and lanes to its people.
              </p>
            </div>
          </div>
        </div>

        {/* Second Row Image */}

        <div className={`row ${styles.second_div}`}>
          <div className={`col-lg-4 ${styles.common_img_div} p-0`}>
            <img
              className={`${styles.common_img}`}
              src="/images/homepage/Places/7.jpg"
              alt="img1"
            />

            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Chitwan</h3>
              <p className={`${styles.hover_para}`}>
                Chitawan National Park is the first established national park in
                Nepal. It belongs to a World Heritage Site and is one of the
                most visited places in Chitwan. This national park conserves
                different species of wild animals and birds including Bengal
                tigers, one-horned rhinoceros, and Asiatic elephant.
              </p>
              {/* hello Icon */}
            </div>
          </div>
          <div className={`col-lg-4 ${styles.common_img_div}`}>
            <img
              className={`${styles.common_img}`}
              src="/images/homepage/Places/3.jpg"
              alt="img2"
            />
            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Mustang</h3>
              <p className={`${styles.hover_para}`}>
                Upper Mustang is mostly popular for adventurous trekking,
                Tibetan culture, and exploring the thousand years of an old
                monastery. On the other hand, Lower Mustang is famous for
                natural scenery, culture and different beautiful landscapes.
                Upper Mustang has a Trans Himalayan climate which is cool and
                semi-arid.
              </p>
              {/* hello Icon */}
            </div>
          </div>
          <div className={`col-lg-4 ${styles.common_img_div} p-0`}>
            <img
              className={`${styles.common_img}`}
              src="/images/homepage/Places/4.jpg"
              alt="img3"
            />
            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Rara Lake</h3>
              <p className={`${styles.hover_para}`}>
                Rara Lake, being surrounded by Rara National Park, has unique
                floral and faunal importance with rare and vulnerable species.
                The park was established in 1976 to preserve the beauty of the
                lake and protect it from sedimentation and adverse human
                activities.
              </p>
              {/* hello Icon */}
            </div>
          </div>
        </div>

        {/* Third Row Image */}
        <div className={`row ${styles.images_div}`}>
          <div
            className={`${styles.img_div_3} col-lg-5 col-md-5 col-sm-12 p-0`}
          >
            <img
              className={`${styles.place_img_3}`}
              src="/images/homepage/Places/5.jpg"
              alt="place"
            />
            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Ilam</h3>
              <p className={`${styles.hover_para}`}>
                An excellent getaway from city life, Ilam is famous for its tea.
                It is a district largely covered in tea bushes with a hill town
                right beside a plantation enjoying views of pristine landscapes
                made up of sloping tea gardens, mountain streams, dense natural
                forests, holy sites and unique culture.
              </p>
              {/* hello Icon */}
            </div>
          </div>
          <div
            className={`${styles.img_div_4} col-lg-7 col-md-7 col-sm-12 pr-0`}
          >
            <img
              className={`${styles.place_img_4}`}
              src="/images/homepage/Places/6.jpg"
              alt="place"
            />
            <div className={`${styles.hover_items} d-flex`}>
              <Icon
                icon="carbon:location-filled"
                className={`${styles.hover_icon}`}
              />
              <h3 className={`${styles.hover_title}`}>Phoksundo Lake</h3>
              <p className={`${styles.hover_para}`}>
                Phoksundo Lake is famous for its magnificent turquoise color and
                te spectacular scenery clearly rank it with the most scenic
                mountain parks in the world. Ringmo village, a typical Tibetan
                village, is scenically nestled in the area. Many beautiful
                glaciers can be found near and above the lake area.
              </p>
              {/* hello Icon */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTrending;
