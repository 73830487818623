import { Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoader from "../../components/common/loader/ReactLoader";
import { resetPasswordSchema } from "../../schemas/forgot-password";
import axiosInstance from "../../services/axiosInstance";
import TextField from "../common/forms/TextField";
import styles from "./forgot.module.css";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const initialValues = {
    uid: uid,
    token: token,
    new_password: "",
    c_password: "",
  };
  return (
    <div className={`${styles.forgot_main_div} container`}>
      {loading && <ReactLoader />}
      <div className={`${styles.forgot_row} row`}>
        <div
          className={`${styles.forgot_col_left} col-lg-6 col-md-6 col-sm-12`}
        >
          <h1 className={`${styles.create_title}`}>Create Strong Password</h1>

          <Formik
            initialValues={initialValues}
            onSubmit={async ({ uid, token, new_password }, { resetForm }) => {
              setLoading(true);
              try {
                const response = await axiosInstance.post(
                  "auth/users/reset_password_confirm/",
                  { uid, token, new_password }
                );
                if (response.status === 204) {
                  setLoading(false);
                  toast.success("Password Changed Successfully");
                  resetForm();
                  navigate("/login");
                }
              } catch (err) {
                setLoading(false);
                resetForm();
                toast.error("Something Went Wrong", err);
              }
            }}
            validateOnMount
            validationSchema={resetPasswordSchema}
          >
            {({ handleSubmit, isValid }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className={`${styles.forgot_form}`}
                >
                  <TextField
                    type="password"
                    name="new_password"
                    label="New Password"
                  />
                  <TextField
                    type="password"
                    name="c_password"
                    label="Confirm Password"
                  />

                  <button
                    type="submit"
                    disabled={!isValid || loading}
                    className={`btn ${styles.forgot_btn}`}
                  >
                    Change Password
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
        <div
          className={`${styles.forgot_col_right} col-lg-6 col-md-6 col-sm-12`}
        >
          <div className={`${styles.forgot_img_div}`}>
            <img
              src="/images/homepage/forgot.png"
              alt="forgot"
              className={`${styles.forgot_img}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
