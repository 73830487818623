// import { Icon } from "@iconify/react";
import React, { useState } from "react";
import Card from "../common/card/Card";
import cardList from "../common/card/cardList";
import cardList2 from "../common/card/cardList2";
import styles from "./showRecommend.module.css";

const ShowRecommend = () => {
  const [visible, setVisible] = useState(3);

  const handleMore = () => {
    setVisible(visible + 3);
  };

  return (
    <div className={`${styles.kyc_container_fluid} container-fluid`}>
      <div className={`${styles.show_recommend_row} row`}>
        <div className={`${styles.show_recommend_form_div} col-lg-12`}>
          <div className={`${styles.show_recommend_title_div}`}>
            <h1 className={`${styles.show_recommend_title}`}>
              Interested Companions
            </h1>
          </div>
          <div className={`${styles.recommend_row} row`}>
            {cardList2.slice(0, visible).map((item) => {
              return (
                <div
                  className={`${styles.interested_card_div} col-lg-4 col-md-3 col-sm-4`}
                >
                  <Card
                    name={item.name}
                    image={item.imgUrl}
                    email={item.email}
                    age={item.age}
                    address={item.address}
                  />
                </div>
              );
            })}
            {visible < cardList.length && (
              <div className={`${styles.load_more_btn_div}`}>
                <button
                  className={`${styles.load_more_btn} btn`}
                  onClick={handleMore}
                >
                  {/* See More
                  <Icon
                    className={`${styles.see_more_icons}`}
                    icon="akar-icons:arrow-down"
                  /> */}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowRecommend;
