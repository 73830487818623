import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import axiosInstance from "../../services/axiosInstance";
import TextField from "../common/forms/TextField";
import styles from "./setting.module.css";

const SettingsPage = () => {
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  return (
    <div>
      <div className={`${styles.security_container_fluid} container-fluid`}>
        <div className={`${styles.recommend_row} row`}>
          <div
            className={`${styles.security_div} col-lg-12 col-md-12 col-sm-8 `}
          >
            <h1 className={`${styles.interest_security_title}`}>
              Change Password
            </h1>

            <Formik
              initialValues={initialValues}
              onSubmit={async (data, { resetForm }) => {
                try {
                  const response = await axiosInstance.post(
                    `auth/users/set_password/`,
                    {
                      current_password: data.current_password,
                      new_password: data.new_password,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${localStorage.getItem("token")}`,
                      },
                    }
                  );
                  if (response.status === 204) {
                    resetForm();
                    toast.success("Password Changed Successfully");
                  }
                } catch (error) {
                  toast.error("Password Change Failed", error);
                }
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={`form-group ${styles.input_field_div}`}>
                      <TextField
                        type="password"
                        name="current_password"
                        label="Current Password *"
                      />
                    </div>
                    <div className={`form-group ${styles.input_field_div}`}>
                      <TextField
                        type="password"
                        name="new_password"
                        label="New Password *"
                      />
                    </div>
                    <div className={`form-group ${styles.input_field_div}`}>
                      <TextField
                        type="password"
                        name="confirm_password"
                        label="Confirm Password *"
                      />
                    </div>
                    <button
                      type="submit"
                      className={`btn ${styles.save_change_btn}`}
                    >
                      Change Password
                    </button>
                  </form>
                );
              }}
            </Formik>
            <div className={styles.security_bottom_div}>
              <h1 className={`${styles.interest_security_bottom_title}`}>
                Deactivate Account
              </h1>
              <p className={styles.interest_sure_para}>
                Are you sure you want to deactivate?
              </p>
              <button type="submit" className={`btn ${styles.deactivate_btn}`}>
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
