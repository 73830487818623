import { Icon } from "@iconify/react";
import React from "react";
import { Modal } from "react-bootstrap";
import PlanTrip from "../../plantrip/PlanTrip";
import styles from "./trip.module.css";

import "./modalBootstrap.css";

const PlanTripModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title
            className={styles.modal_header_title}
            id="contained-modal-title-vcenter"
          >
            <h2 className={styles.trip_modal_header_title}>Plan Trip</h2>
            <span onClick={props.onHide}>
              <Icon
                className={`${styles.close_trip_btn_icon}`}
                icon="eva:close-fill"
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`${styles.modal_body_div}`}>
            {/* <InterestPage /> */}
            <PlanTrip />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div className={`${styles.trip_footer_btn_div}`}>
            <button
              onClick={handleInterestModal}
              className={`${styles.trip_footer_btn}`}
            >
              Proceed
            </button>
          </div> */}
          <div className={`${styles.trip_modal_footer_div}`}></div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlanTripModal;
