import React from "react";
import styles from "./homeTitle.module.css";

const HomeTitle = (props) => {
  return (
    <div>
      <div className={`${styles.title_div}`}>
        <h1 className={`${styles.title}`}>{props.title}</h1>
        <h3 className={`${styles.subtitle}`}>{props.subtitle}</h3>
      </div>
    </div>
  );
};

export default HomeTitle;
