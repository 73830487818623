import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const profileSchema = Yup.object({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "* First name must be atleast 2 characters"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "* First name must be atleast 2 characters"),
  email: Yup.string()
    .email("* Please enter a valid email")
    .required("* Email is required"),
  mobile: Yup.string()
    .required("This field is Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(10, "* Mobile number must be atleast 10 digits")
    .max(10, "* Mobile number should not be more than 10 digits"),
  address: Yup.string(),
  file: Yup.mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "File is too large",
      (value) => !value || (value && value.size <= 1024 * 1024)
    )
    .test(
      "FILE_FORMAT",
      "Unsupported File Format",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});
