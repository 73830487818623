import { Icon } from "@iconify/react";
import React from "react";
import styles from "./homeService.module.css";

const HomeServiceUI = (props) => {
  return (
    <div className={`${styles.service_img_div}`}>
      <img
        className={styles.service_img}
        src={props.imageUrl}
        alt="service_img"
      />
      <div className={`${styles.hover_items}`}>
        <Icon icon={props.icon} className={`${styles.hover_icon}`} />
        <h4 className={`${styles.hover_title}`}>{props.name}</h4>
        <h3 className={`${styles.hover_rating}`}>{props.rating}</h3>
        <h4 className={`${styles.hover_address}`}>{props.address}</h4>
        <h3 className={`${styles.hover_price}`}>{props.price}</h3>
      </div>
    </div>
  );
};

export default HomeServiceUI;
