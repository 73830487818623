import React, { useEffect } from "react";
import styles from "./plantrip.module.css";
import CustomSelect from "../common/forms/CustomSelect";
import { Formik } from "formik";
import InterestCheckbox from "../common/forms/InterestCheckbox";
import InterestedRadio from "../common/forms/InterestedRadio";
import monthName from "./monthsList";
import axiosInstance from "../../services/axiosInstance";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PlanTrip = () => {
  const navigate = useNavigate();
  // const [tripData, setTripData] = useState(null);
  // const [interestData, setInterestData] = useState(null);

  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const initialValues = {
    prefered_date: "",
    prefered_location: "",
    prefered_gender: "",
    meditation: false,
    rafting: false,
    partying: false,
    driving: false,
    camping: false,
    cooking: false,
    hiking: false,
    biking: false,
    sight_seeing: false,
    religious: false,
    budget: "",
    prefered_age_group: "",
    user: userData?.id,
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    // const tripResponse = async () => {
    //   const response = await axiosInstance.get(`trips/`, {
    //     headers: {
    //       Authorization: `Token ${token}`,
    //     },
    //   });
    //   setTripData(response?.data[0]);
    //   // console.log("Response from trip", response?.data[0]);
    // };

    // const interestResponse = async () => {
    //   const response = await axiosInstance.get(`interest/`, {
    //     headers: {
    //       Authorization: `Token ${token}`,
    //     },
    //   });
    //   setInterestData(response?.data[0]);
    //   console.log("Response from interest", response?.data[0]);
    // };
    // interestResponse();
    // tripResponse();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <div className={`${styles.plan_trip_main_div} container `}>
          {
            <Formik
              initialValues={initialValues}
              onSubmit={async (data, { resetForm }) => {
                try {
                  const response = await axiosInstance.post(
                    `trips/`,
                    {
                      prefered_date: data.prefered_date,
                      prefered_location: data.prefered_location,
                      prefered_gender: data.prefered_gender,
                      meditation: data.meditation,
                      rafting: data.rafting,
                      partying: data.partying,
                      driving: data.driving,
                      camping: data.camping,
                      cooking: data.cooking,
                      hiking: data.hiking,
                      biking: data.biking,
                      sight_seeing: data.sight_seeing,
                      religious: data.religious,
                      budget: data.budget,
                      prefered_age_group: data.prefered_age_group,
                      user: data.user,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                      },
                    }
                  );

                  if (response.status === 201) {
                    resetForm();
                    toast.success("Trip planned successfully");
                  }

                  console.log("Response trip", response);
                } catch (error) {
                  toast.error("Something went wrong", error);
                }
                // alert(JSON.stringify(data, null, 2));
              }}
              enableReinitialize
            >
              {(values) => {
                return (
                  <form onSubmit={values.handleSubmit}>
                    <div className={`${styles.plan_trip_form}`}>
                      <div className={`form-group ${styles.input_field_div}`}>
                        <CustomSelect
                          name="prefered_date"
                          label="Favourable Month "
                        >
                          <option value="">Select Favourable Month </option>
                          {monthName.map((month) => {
                            return (
                              <option
                                key={month.id}
                                value={`${month.value}${currentYear}`}
                              >
                                {month.monthName}
                              </option>
                            );
                          })}
                        </CustomSelect>
                      </div>
                      <div className={`form-group ${styles.input_field_div}`}>
                        <CustomSelect
                          name="prefered_location"
                          label="Location "
                        >
                          <option value="">Select Location </option>
                          <option value="K">Kanyam</option>
                          <option value="G">Gosaikunda</option>
                          <option value="M">Mardi</option>
                        </CustomSelect>
                      </div>

                      <div className={`${styles.interest_selection_row}`}>
                        <div className={`${styles.interest_selection_div}`}>
                          <h1 className={`${styles.interest_selection_title}`}>
                            Select your Interest
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6 `}
                            >
                              <InterestCheckbox
                                name="rafting"
                                type="checkbox"
                                label="Rafting"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="sight_seeing"
                                type="checkbox"
                                label="Sight-Seeing"
                              />
                            </div>
                            {/* <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="dancing"
                                type="checkbox"
                                label="Dancing"
                              />
                            </div> */}
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="partying"
                                type="checkbox"
                                label="Partying"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="cooking"
                                type="checkbox"
                                label="Cooking"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="driving"
                                type="checkbox"
                                label="Driving"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="hiking"
                                type="checkbox"
                                label="Hiking"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="meditation_and_yoga"
                                type="checkbox"
                                label="Meditation & Yoga"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="religious"
                                type="checkbox"
                                label="Pilgrimage"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="camping"
                                type="checkbox"
                                label="Camping"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="biking"
                                type="checkbox"
                                label="Biking"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${styles.interest_budget_row}`}>
                        <div className={`${styles.interest_budget_div}`}>
                          <h1 className={`${styles.interest_budget_title}`}>
                            Prefered Budget Level
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="budget"
                                type="radio"
                                value="B10"
                                label="Below 10K"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="budget"
                                type="radio"
                                value="B20"
                                label="Below 20K"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="budget"
                                type="radio"
                                value="B30"
                                label="Below 30K"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Prefered Age Group */}

                      <div className={`${styles.interest_age_row}`}>
                        <div className={`${styles.interest_age_div}`}>
                          <h1 className={`${styles.interest_age_title}`}>
                            Prefered Age Group
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_age_group"
                                type="radio"
                                value="1"
                                label="18-22"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_age_group"
                                type="radio"
                                value="2"
                                label="23-26"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-2 col-md- col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_age_group"
                                type="radio"
                                value="3"
                                label="27-30"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_age_group"
                                type="radio"
                                value="4"
                                label="30+"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Gender Preference */}
                      <div className={`${styles.interest_gender_row}`}>
                        <div className={`${styles.interest_gender_div}`}>
                          <h1 className={`${styles.interest_gender_title}`}>
                            Gender Preference
                            <span className={`${styles.gender_subtitle}`}>
                              ( Both gender can be selected. )
                            </span>
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_gender"
                                type="radio"
                                value="M"
                                label="Male"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_gender"
                                type="radio"
                                value="F"
                                label="Female"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="prefered_gender"
                                type="radio"
                                value="A"
                                label="Any"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.trip_footer_btn_div}`}>
                      <button
                        type="submit"
                        className={`${styles.trip_footer_btn}`}
                      >
                        Proceed
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          }
        </div>
      </div>
    </>
  );
};

export default PlanTrip;
