import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";

// eslint-disable-next-line

import ScrollToTop from "./ScrollToTop";
import CustomizedToaster from "./components/common/customToaster/CustomizedToaster";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <CustomizedToaster />
    <App />
  </BrowserRouter>
);
