import React from "react";
// import { ProgressBar } from "react-bootstrap";
import styles from "./loader.module.css";

const ReactLoader = () => {
  return (
    <div className={`${styles.loader_div}`}>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: "100%", color: "black", backgroundColor: "#ff770a" }}
        ></div>
      </div>
    </div>
  );
};

// color="#ff770a"
export default ReactLoader;
