import React from "react";
import styles from "./homeProject.module.css";
import { projectList } from "./projectList";
import ProjectUI from "./ProjectUI";

const HomeProject = () => {
  return (
    <div>
      <div className={`${styles.home_project_section_container} container`}>
        <div className={`${styles.home_project_section}`}>
          <h1 className={`${styles.project_title}`}>
            6 Months of Following The Dream
          </h1>
          <div className={`row ${styles.home_testimonial_row}`}>
            {projectList?.map((list, index) => {
              return (
                <div key={index} className={`col-lg-4 col-md-4 col-sm-6`}>
                  <ProjectUI imageUrl={list?.imageUrl} name={list?.name} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProject;
