import React, { useState } from "react";
// import { timeline } from "./timelineList";
import styles from "./timeline.module.css";
import { useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";

const Timeline = () => {
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const [data, setData] = useState([]);

  useEffect(() => {
    const historyTimeline = async () => {
      const response = await axiosInstance.get(
        `trips/history/?user=${userData?.id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("History Timeline", response?.data);
      setData(response?.data);
    };

    historyTimeline();
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={`${styles.timeline_container_fluid} container`}>
        <div className={`${styles.timeline_row} row`}>
          <div className={`${styles.timeline_div} col-lg-12`}>
            <h2 className={`${styles.timeline_history_title}`}>
              Travel History
            </h2>
          </div>

          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className={`${styles.timeline_history_col} col-lg-12`}
              >
                <div className={`${styles.timeline_history_div}`}>
                  <p className={`${styles.timeline_hisotry_para}`}>
                    Travelled to{" "}
                    {/* {item.prefered_location === "M" ? "Mardi Himal" : "Kanyam"} */}
                    {item.prefered_location === "M"
                      ? "Mardi Himal"
                      : item.prefered_location === "K"
                      ? "Kanyam"
                      : item.prefered_location === "G" && "Gosiakunda"}
                  </p>
                  <p className={`${styles.timeline_hisotry_date}`}>
                    {item.prefered_date}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
