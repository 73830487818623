import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./gender.module.css";

const GenderPreference = () => {
  // const handleImage = (event) => {
  //   const male = event.targe.value;
  //   const female = event.target.value;
  //   console.log("Values", male, female);
  // };

  return (
    <div>
      <div className={`${styles.gender_main_div}`}>
        <span className={`${styles.skip_btn}`}>
          <NavLink to="/">Skip</NavLink>
        </span>
        <div className={`${styles.gender_top_div} `}>
          <h1 className={`${styles.choose_title}`}>Choose Gender Preference</h1>
        </div>
        <p className={`${styles.choose_para}`}>
          Choose the gender that you are comfortable with and want to share
          travel experience. <br />
          <span className={`${styles.para_bottom}`}>
            ( Both of the gender can be selected. )
          </span>
        </p>
        <div className={`${styles.gender_img_div}`}>
          {/* <input
            type="checkbox"
            class="chk "
            id="male"
            name="img1"
            value="M"
            onChange={handleImage}
          />
          <label for="male"> */}
          <img
            className={`${styles.gender_img_male}`}
            src="/images/homepage/gender/male.png"
            alt="malee"
          />
          {/* </label> */}
          {/* <input
            type="checkbox"
            class="chk "
            id="female"
            name="img1"
            value="F"
            onChange={handleImage}
          /> */}
          {/* <label for="female"> */}
          <img
            className={`${styles.gender_img_female}`}
            src="/images/homepage/gender/female.png"
            alt="malee"
          />
          {/* </label> */}
        </div>

        <NavLink to="/your-interest" className={`${styles.next_btn} btn`}>
          Next
        </NavLink>
      </div>
    </div>
  );
};

export default GenderPreference;
