import { Outlet, Route, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import FooterGhumfir from "./layouts/FooterGhumfir";
import HeaderGhumfir from "./layouts/HeaderGhumfir";
import Account from "./pages/account/Account";
import EmailSent from "./pages/emailSent/EmailSent";
import EmailVerification from "./pages/emailVerification/EmailVerification";
import PageNotFound from "./pages/error/404/PageNotFound";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import PasswordReset from "./pages/forgotPassword/PasswordReset";
import GenderPreference from "./pages/gender/GenderPreference";
import HomePage from "./pages/home/HomePage";
import InterestPage from "./pages/interest/InterestPage";
import KycForm from "./pages/kyc/KycForm";
import Login from "./pages/login/Login";
import ProfilePage from "./pages/profilePage/ProfilePage";
import Recommendation from "./pages/recommend/Recommendation";
import SettingsPage from "./pages/settings/SettingsPage";
import ShowRecommend from "./pages/showRecommend/ShowRecommend";
import Signup from "./pages/signup/Signup";
import TermsAndCondition from "./pages/termsCondition/TermsAndCondition";
import Timeline from "./pages/timeline/Timeline";
import YourInterest from "./pages/yourInterest/YourInterest";

function App() {
  // const token = localStorage.getItem("token");
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeaderGhumfir />
              <Outlet />
            </>
          }
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/email-sent" element={<EmailSent />} />
          <Route path="/activate/:uid/:token" element={<EmailVerification />} />
          <Route path="/gender-preference" element={<GenderPreference />} />
          <Route path="/your-interest" element={<YourInterest />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/password-reset/:uid/:token"
            element={<PasswordReset />}
          />
          <Route path="account" element={<Account />}>
            {/* <Route index element={<Account />} /> */}
            <Route index element={<ProfilePage />} />
            <Route path="my-profile" end element={<ProfilePage />} />
            <Route path="kyc-details" element={<KycForm />} />
            <Route path="my-interest" element={<InterestPage />} />
            <Route path="recommendation" element={<Recommendation />} />
            <Route path="match" element={<ShowRecommend />} />
            <Route path="timeline" element={<Timeline />} />
            <Route path="security-settings" element={<SettingsPage />} />
            <Route path="recommend" element={<Recommendation />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ScrollToTop
        top={50}
        smooth
        // color="#ff770a"
        color="#fff"
        style={{
          backgroundColor: "#ff770a",
          borderRadius: "50px",
          // boxShadow: "0px 0px 10px #ff770a",
          boxShadow: "0px 1px 3px  #ff770a, 0px 1px 8px  #ff770a",
          // color: "#fff !important",
          //       0px 4px 3px rgba(0, 0, 0, 0.04),
          // 0px 10px 8px rgba(151, 151, 151, 0.1) !important;
        }}
      />
      <FooterGhumfir />
    </div>
  );
}

export default App;
