import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./page.module.css";
const PageNotFound = () => {
  return (
    <div>
      <div className={`${styles.page_not_found_div}`}>
        <img
          className={`${styles.page_not_found_img}`}
          src="/images/homepage/404/404.png"
          alt="page_not_found"
        />
        <h2 className={`${styles.page_oops}`}>Oops!!!</h2>
        <p className={`${styles.page_wrong}`}>Something went wrong</p>
        <NavLink to="/" className={`btn btn-success ${styles.page_home_btn}`}>
          Homepage
        </NavLink>
        <img
          className={`${styles.ghumfir_logo}`}
          src="/images/logo_dark.png"
          alt="ghumfir_logo"
        />
      </div>
    </div>
  );
};

export default PageNotFound;
