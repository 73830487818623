export const testimonailList = [
  {
    id: 1,
    imageUrl: "/images/homepage/Testimonials/puntu.jpg",
    name: "Bibesh Paudel",
    desp: "Mobile Developer",
    rating: "⭐⭐⭐",
    address: "Baglung",
    review:
      "Scelerisque massa pharetra mattis pulvinar habitant lorem neque tristique ac. Sit ullamcorper fermentum purus aliquet nunc, eu ullamcorper. Scelerisque massa pharetra mattis pulvinar habitant.  ",
  },
  {
    id: 2,
    imageUrl: "/images/homepage/Testimonials/solta.jpg",
    name: "Saugat Ghimire",
    desp: "UI/UX Developer",
    rating: "⭐⭐⭐⭐",
    address: "Chitwan",
    review:
      "Scelerisque massa pharetra mattis pulvinar habitant lorem neque tristique ac. Sit ullamcorper fermentum purus aliquet nunc, eu ullamcorper. Scelerisque massa pharetra mattis pulvinar habitant ",
  },
  {
    id: 3,
    imageUrl: "/images/homepage/Testimonials/subash.jpg",
    name: "Subash Pandey",
    desp: "Web Developer",
    rating: "⭐⭐⭐⭐⭐",
    address: "Kanchanpur",
    review:
      "Scelerisque massa pharetra mattis pulvinar habitant lorem neque tristique ac. Sit ullamcorper fermentum purus aliquet nunc, eu ullamcorper. Scelerisque massa pharetra mattis pulvinar habitant ",
  },
  {
    id: 4,
    imageUrl: "/images/homepage/Testimonials/saugat.jpg",
    name: "Saugat Maharjan",
    desp: "UI/UX Developer",
    rating: "⭐⭐⭐⭐",
    address: "Kathmandu",
    review:
      "Scelerisque massa pharetra mattis pulvinar habitant lorem neque tristique ac. Sit ullamcorper fermentum purus aliquet nunc, eu ullamcorper. Scelerisque massa pharetra mattis pulvinar habitant ",
  },
  {
    id: 5,
    imageUrl: "/images/homepage/Testimonials/taku.jpg",
    name: "Deepson Shrestha",
    desp: "Backend Developer",
    rating: "⭐⭐⭐⭐⭐",
    address: "Kathmandu",
    review:
      "Scelerisque massa pharetra mattis pulvinar habitant lorem neque tristique ac. Sit ullamcorper fermentum purus aliquet nunc, eu ullamcorper. Scelerisque massa pharetra mattis pulvinar habitant ",
  },
];
