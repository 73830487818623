import React, { useState } from "react";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import styles from "./header.module.css";
import { useEffect } from "react";
import axiosInstance from "../services/axiosInstance";
import toast from "react-hot-toast";
// import axiosInstance from "../services/axiosInstance";
// import toast from "react-hot-toast";

const HeaderGhumfir = () => {
  const token = localStorage.getItem("token");
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const [navbar, setNavbar] = useState(false);
  const [userData, setUserData] = useState();

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    const userData = async () => {
      try {
        const response = await axiosInstance.get(
          "auth/users/me/",

          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        setUserData(response?.data);

        localStorage.setItem("userInfo", JSON.stringify(response.data));
      } catch (error) {
        // toast.error("Please login to continue");
      }
    };

    userData();
  }, [token]);

  window.addEventListener("scroll", changeBackground);

  const handleLogout = async () => {
    // localStorage.removeItem("token");
    // window.location.reload();
    // navigate("/login");
    try {
      const logoutResponse = await axiosInstance.post(
        "auth/token/logout/",
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (logoutResponse.status === 204) {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        navigate("/login");
      }
    } catch (error) {
      toast.error("Something went wrong", error);
    }
  };

  return (
    <div>
      <div className={`${styles.home_container}  conatainer-fluid`}>
        <div className="navbar_conatiner_div">
          <Navbar
            className={
              navbar
                ? `${styles.header_navbar_active} `
                : `${styles.header_navbar}`
            }
            fixed="top"
            expand="lg"
          >
            <NavLink to="/">
              {pathname === "/" && !navbar ? (
                <img
                  src="/images/logo_light.png"
                  className={`d-inline-block align-top ${styles.ghumfir_logo}`}
                  alt="ghumfir_logo"
                />
              ) : (
                <img
                  src="/images/logo_dark.png"
                  className={`d-inline-block align-top ${styles.ghumfir_logo}`}
                  alt="ghumfir_logo"
                />
              )}
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {pathname === "/" ? (
                <>
                  <Nav
                    className={`${styles.nav_items} nav_items my-2 my-lg-0 `}
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    {token && token !== "undefined" ? (
                      <>
                        {/* <button className={`${styles.login_btn} btn`}> */}
                        {/* <NavLink to="/match">Match</NavLink> */}
                        <NavLink
                          to="/account/recommend"
                          className={`${styles.nav_link_match_item}`}
                        >
                          <span className={`${styles.nav_item_match_text}`}>
                            Match
                          </span>
                        </NavLink>
                        <Dropdown
                          className={`${styles.login_dropdown_btn} btn`}
                        >
                          <Dropdown.Toggle
                          // variant="success"
                          // id="dropdown-basic"
                          >
                            <img
                              className={`${styles.user_icon} mr-2`}
                              src="/images/user_logo.png"
                              alt="logo"
                            />

                            <span className={`${styles.nav_item_login_text}`}>
                              {userData === null || undefined
                                ? "Account"
                                : userData?.first_name}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <button className={`${styles.dropdown_items}`}>
                                <NavLink
                                  to="/account/my-profile"
                                  className={`${styles.my_account_link}`}
                                >
                                  <Icon
                                    className={`${styles.my_account_icon}`}
                                    icon="bxs:user-circle"
                                  />
                                  <span className={`${styles.my_account_text}`}>
                                    My Profile
                                  </span>
                                </NavLink>
                              </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <button
                                onClick={handleLogout}
                                className={`${styles.dropdown_items}`}
                              >
                                <Icon
                                  className={`${styles.logout_icon}`}
                                  icon="ant-design:logout-outlined"
                                />
                                <span className={`${styles.logout_text}`}>
                                  Logout
                                </span>
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <>
                        <button className={`${styles.login_btn} btn`}>
                          <NavLink
                            to={`/login`}
                            className={`${styles.nav_login_item}`}
                          >
                            Login
                            <Icon
                              className={`${styles.sign_in_icon}`}
                              icon="la:sign-in-alt"
                            />
                          </NavLink>
                        </button>
                        <NavLink
                          to={`/signup`}
                          className={`${styles.nav_item_signup}`}
                        >
                          <button className={`${styles.signup_btn} btn`}>
                            Sign Up
                            <Icon
                              className={`${styles.sign_in_icon}`}
                              icon="la:sign-in-alt"
                            />
                          </button>
                        </NavLink>
                      </>
                    )}
                  </Nav>
                </>
              ) : pathname === "/login" ? (
                <>
                  <Nav
                    className={`${styles.nav_items} nav_items my-2 my-lg-0 `}
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    {token && token !== "undefined" ? (
                      <>
                        <NavLink
                          to="/account/recommend"
                          className={`${styles.nav_link_match_item}`}
                        >
                          <span
                            className={`${styles.nav_item_login_match_text}`}
                            style={{
                              color: "black !important",
                            }}
                          >
                            Match
                          </span>
                        </NavLink>

                        <Dropdown
                          className={`${styles.login_dropdown_btn} btn`}
                          style={{
                            backgroundColor: "black",
                            border: "1px solid black",
                          }}
                        >
                          <Dropdown.Toggle
                          // variant="success"
                          // id="dropdown-basic"
                          >
                            <img
                              className={`${styles.user_icon} mr-2`}
                              src="/images/user_logo.png"
                              alt="logo"
                            />
                            <span className={`${styles.nav_item_login_text}`}>
                              {userData === null || undefined
                                ? "Account"
                                : userData?.first_name}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <button className={`${styles.dropdown_items}`}>
                                <NavLink
                                  to="/account/my-profile"
                                  className={`${styles.my_account_link}`}
                                >
                                  <Icon
                                    className={`${styles.my_account_icon}`}
                                    icon="bxs:user-circle"
                                  />
                                  <span className={`${styles.my_account_text}`}>
                                    My Profile
                                  </span>
                                </NavLink>
                              </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <button
                                onClick={handleLogout}
                                className={`${styles.dropdown_items}`}
                              >
                                <Icon
                                  className={`${styles.logout_icon}`}
                                  icon="ant-design:logout-outlined"
                                />
                                <span className={`${styles.logout_text}`}>
                                  Logout
                                </span>
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <>
                        <button className={`${styles.login_btn} btn`}>
                          <small className={`${styles.small_tag}`}>
                            New to ghumfir?
                          </small>
                          <NavLink
                            to={`/signup`}
                            className={`${styles.nav_login_item}`}
                          >
                            Signup
                            <Icon
                              className={`${styles.sign_in_icon}`}
                              icon="la:sign-in-alt"
                            />
                          </NavLink>
                        </button>
                      </>
                    )}
                  </Nav>
                </>
              ) : (
                <>
                  <Nav
                    className={`${styles.nav_items} nav_items my-2 my-lg-0 `}
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    {token && token !== "undefined" ? (
                      <>
                        <NavLink
                          to="/account/recommend"
                          className={`${styles.nav_link_match_item}`}
                        >
                          <span
                            className={`${styles.nav_item_login_match_text}`}
                          >
                            Match
                          </span>
                        </NavLink>
                        <Dropdown
                          className={`${styles.login_dropdown_btn} btn`}
                          // style={{}}
                        >
                          <Dropdown.Toggle
                          // variant="success"
                          // id="dropdown-basic"
                          >
                            <img
                              className={`${styles.user_icon} mr-2`}
                              src="/images/user_logo.png"
                              alt="logo"
                              style={{
                                backgroundColor: "black",
                              }}
                            />
                            <span
                              className={`${styles.nav_item_login_span}`}
                              // style={{
                              //   color: "black",
                              // }}
                            >
                              {userData === null || undefined
                                ? "Account"
                                : userData?.first_name}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <button className={`${styles.dropdown_items}`}>
                                <NavLink
                                  to="/account/my-profile"
                                  className={`${styles.my_account_link}`}
                                >
                                  <Icon
                                    className={`${styles.my_account_icon}`}
                                    icon="bxs:user-circle"
                                  />
                                  <span className={`${styles.my_account_text}`}>
                                    My Profile
                                  </span>
                                </NavLink>
                              </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <button
                                onClick={handleLogout}
                                className={`${styles.dropdown_items}`}
                              >
                                <Icon
                                  className={`${styles.logout_icon}`}
                                  icon="ant-design:logout-outlined"
                                />
                                <span className={`${styles.logout_text}`}>
                                  Logout
                                </span>
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <>
                        <button className={`${styles.login_btn} btn`}>
                          <small className={`${styles.small_tag}`}>
                            Already a member?
                          </small>
                          <NavLink
                            to={`/login`}
                            className={`${styles.nav_login_item}`}
                          >
                            Login
                            <Icon
                              className={`${styles.sign_in_icon}`}
                              icon="la:sign-in-alt"
                            />
                          </NavLink>
                        </button>
                      </>
                    )}
                  </Nav>
                </>
              )}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default HeaderGhumfir;
