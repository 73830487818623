import * as Yup from "yup";

export const signupSchema = Yup.object({
  first_name: Yup.string()
    .required("* First name is required")
    .min(2, "* First name must be atleast 2 characters")
    .max(15, "* Last name must be atmost 15 characters"),
  last_name: Yup.string()
    .required("* Last name is required")
    .min(2, "* First name must be atleast 2 characters")
    .max(15, "* Last name must be atmost 15 characters"),
  email: Yup.string()
    .email("* Please enter a valid email")
    .required("* Email is required"),
  password: Yup.string()
    .required("* Password is required")
    .min(8, "password must contain 8 or more characters"),
  // cPassword: Yup.string()
  //   .required("* Please Retype your password")
  //   .min(8, "* Password must contain 8 or more characters")
  //   .oneOf([Yup.ref("password")], "* Your passwords do not match."),

  mobile: Yup.string()
    .required("This field is Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(10, "* Mobile number must be atleast 10 digits")
    .max(10, "* Mobile number should not be more than 10 digits"),

  gender: Yup.string().required("reuired field"),
  has_18_agreement: Yup.boolean()
    .required("Please agree our terms and conditions to continue")
    .oneOf([true], "I am above 18+"),
  has_agreed_tnc: Yup.boolean()
    .required("Please agree our terms and conditions to continue")
    .oneOf([true], "Please agree our terms and conditions to continue"),
});

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("* Please enter a valid email")
    .required("* Email is required"),
  password: Yup.string()
    .required("* Password is required")
    .min(8, "password must contain 8 or more characters"),
});
