import { Icon } from "@iconify/react";
import { Formik } from "formik";
import React, { useState } from "react";
import { profileSchema } from "../../schemas/profile-schema";
import TextField from "../common/forms/TextField";
import styles from "./profile.module.css";
import ProfilePreview from "./ProfilePreview";
import "./profile.css";
import { useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";

const ProfilePage = () => {
  const token = localStorage.getItem("token");
  const [profileData, setProfileData] = useState(null);
  const fileRef = React.useRef(null);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
    user_pp: "",
  };

  useEffect(() => {
    const profileResponse = async () => {
      const response = await axiosInstance.get("auth/users", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setProfileData(response?.data[0]);
      // console.log("Profile Response", response?.data);
    };

    profileResponse();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("Profile Data", profileData);

  return (
    <div className={`${styles.profile_page_div} container-fluid`}>
      <div className={`${styles.profile_page_div_row} row`}>
        <div className={`${styles.sidebar_div} col-lg-12 col-md-12 `}>
          <div className={`${styles.profile_div}`}>
            <div className={`${styles.profile_page_title}`}>
              <h3>Profile & Details</h3>
            </div>
            {/* <div className={`${styles.profile_image_div}`}>
              <img
                src="/images/homepage/profile/profile.png"
                alt="profile_image"
                className={`${styles.profile_image}`}
              />
              <div className={`${styles.change_div}`}>
                <Icon
                  icon={"akar-icons:edit"}
                  className={`${styles.edit_icon}`}
                />
                <span className={`${styles.change_text}`}>Change</span>
              </div>
            </div> */}
            <div className={`${styles.profile_page_details}`}>
              <div className={`${styles.form_col} col-lg-6 col-md-6 col-sm-12`}>
                <Formik
                  initialValues={profileData || initialValues}
                  onSubmit={async (values, { resetForm }) => {
                    alert(JSON.stringify(values, null, 2));
                    resetForm();
                  }}
                  validateOnMount
                  validationSchema={profileSchema}
                  enableReinitialize
                >
                  {(values) => {
                    return (
                      <div className={`${styles.form_div}`}>
                        <form onSubmit={values.handleSubmit} autoComplete="off">
                          <div className="row">
                            <div
                              className={`col-lg-12 col-md-12 ${styles.img_col_div}`}
                            >
                              <div className={`${styles.profile_image_div}`}>
                                {values.values.file ? (
                                  <>
                                    <ProfilePreview file={values.values.file} />
                                  </>
                                ) : (
                                  <img
                                    src="/images/homepage/profile/profile.png"
                                    alt="profile_image"
                                    className={`${styles.profile_image}`}
                                  />
                                )}
                                <input
                                  type="file"
                                  ref={fileRef}
                                  name="user_pp"
                                  hidden
                                  onChange={(event) => {
                                    values.setFieldValue(
                                      "file",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                {values.errors.file && (
                                  <small style={{ color: "red" }}>
                                    *{values.errors.file}{" "}
                                  </small>
                                )}
                                <div
                                  onClick={() => {
                                    fileRef.current.click();
                                  }}
                                  className={`${styles.change_div}`}
                                >
                                  <Icon
                                    icon={"akar-icons:edit"}
                                    className={`${styles.edit_icon}`}
                                  />
                                  <span className={`${styles.change_text}`}>
                                    Change
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="text"
                                name="first_name"
                                label="First Name *"
                              />
                            </div>
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="text"
                                name="last_name"
                                label="Last Name *"
                              />
                            </div>
                          </div>
                          <TextField
                            type="email"
                            name="email"
                            label="Email *"
                            disabled={true}
                          />
                          <TextField
                            type="number"
                            name="mobile"
                            label="Phone Number *"
                          />

                          <button
                            className={`btn btn-primary  ${styles.submit_btn}`}
                            type="submit"
                            name="terms"
                            disabled={!values.isValid}
                          >
                            Save Changes
                          </button>
                        </form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
