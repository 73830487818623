import { useField } from "formik";
import React from "react";
import styles from "./radio.module.css";

const InterestedRadio = (props) => {
  const [field] = useField({
    name: props.name,
    type: props.type,
    value: props.value,
  });
  return (
    <>
      <label className={` ${styles.radio_div_label} text-gray-500 font-bold`}>
        <input
          {...field}
          className={`${styles.radio_input} mr-2 leading-tight`}
          type="radio"
        />
        <span className={`${styles.radio_label} text-sm`}>{props.label}</span>
      </label>
    </>
  );
};

export default InterestedRadio;
