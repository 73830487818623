import { Icon } from "@iconify/react";
import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./modal.module.css";
import "./modalBootstrap.css";

const CommonModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title
            className={styles.modal_header_title}
            id="contained-modal-title-vcenter"
          >
            <img
              className={styles.modal_img}
              src="/images/logo_dark.png"
              alt="logo"
            />
            <span onClick={props.onHide}>
              <Icon
                className={`${styles.close_btn_icon}`}
                icon="eva:close-fill"
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className={`${styles.body_title}`}>TERMS & CONDITIONS</h4>
          <p className={`${styles.body_para}`}>
            PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS
            WEBSITE AND YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS
            OF THIS AGREEMENT.
            <br />
            <br />
            This Terms of Service Agreement (the "Agreement") governs your use
            of this website, https://ghumfir.zettaminds.tech/ (the "Website"),
            Ghumfir ("Ghumfir") offer opportunity to find other interested
            companion on this Website, or your interest available to other users
            on this Website. This Agreement includes, and incorporates by this
            reference, the policies and guidelines referenced below. Ghumfir
            reserves the right to change or revise the terms and conditions of
            this Agreement at any time by posting any changes or a revised
            Agreement on this Website. Ghumfir will alert you that changes or
            revisions have been made by indicating on the top of this Agreement
            the date it was last revised. The changed or revised Agreement will
            be effective immediately after it is posted on this Website. Your
            use of the Website following the posting any such changes or of a
            revised Agreement will constitute your acceptance of any such
            changes or revisions. Ghumfir encourages you to review this
            Agreement whenever you visit the Website to make sure that you
            understand the terms and conditions governing use of the Website.
            This Agreement does not alter in any way the terms or conditions of
            any other written agreement you may have with Ghumfir for other
            funtions or services. If you do not agree to this Agreement
            (including any referenced policies or guidelines), please
            immediately terminate your use of the Website. If you would like to
            print this Agreement, please click the print button on your browser
            toolbar.
            <br />
            <br />
            <span className={styles.bold_title}>I. SERVICES </span>
            <br />
            Terms of Offer. This Website offers formatching of other interest to
            recommend the user (the "Services"). By submitting your interest for
            form through this Website, you agree to the terms set forth in this
            Agreement. Customer Solicitation: Unless you notify our third party
            call center reps or direct Ghumfir customer care reps, while they
            are calling you, of your desire to opt out from further direct
            company communications and solicitations, you are agreeing to
            continue to receive further emails and call solicitations Ghumfir
            and its designated in house or third party call team(s). Opt Out
            Procedure: We provide 3 easy ways to opt out of from future
            solicitations: 1. You may use the opt out link found in any email
            solicitation that you may receive. 2. You may also choose to opt
            out, via sending your email address to: ghumfir@gmail.com 3. You may
            send a written remove request to Balkumari, Lalitpur. Proprietary
            Rights. Ghumfir has proprietary rights and trade secrets in the
            Services. You may not copy, reproduce, resell or redistribute any
            funtionalities manufactured and/or distributed by Ghumfir. Ghumfir
            also has rights to all trademarks and trade dress and specific
            layouts of this webpage, including calls to action, text placement,
            images and other information.
            <br />
            <br />
            <span className={styles.bold_title}>II. WEBSITE</span>
            <br />
            YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK. THE
            WEBSITE AND PRODUCTS ARE OFFERED ON AN "AS IS" AND "AS AVAILABLE"
            BASIS. Ghumfir EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT, OR
            ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS.
            ("PRODUCTS" INCLUDE TRIAL PRODUCTS.) WITHOUT LIMITING THE GENERALITY
            OF THE FOREGOING, Ghumfir MAKES NO WARRANTY: THAT THE INFORMATION
            PROVIDED ON THIS WEBSITE IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
            THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS
            ACCURATE, RELIABLE, COMPLETE, OR TIMELY. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE WILL
            CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. AS TO THE RESULTS
            THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS OR THAT DEFECTS IN
            PRODUCTS WILL BE CORRECTED. REGARDING ANY PRODUCTS PURCHASED OR
            OBTAINED THROUGH THE WEBSITE. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY
            NOT APPLY TO YOU. <br />
            Use of Website Ghumfir is not responsible for any damages resulting
            from use of this website by anyone. You will not use the Website for
            illegal purposes. You will (1) abide by all applicable local, state,
            national, and international laws and regulations in your use of the
            Website (including laws regarding intellectual property), (2) not
            interfere with or disrupt the use and enjoyment of the Website by
            other users, (3) not use for fraud actions on the Website, (4) not
            engage, directly or indirectly, in transmission of "spam", chain
            letters, junk mail or any other type of unsolicited communication,
            and (5) not defame, harass, abuse, or disrupt other users of the
            Website.
            <br /> <br />
            <span className={styles.bold_title}>License </span>
            <br /> By using this Website, you are granted a limited,
            non-exclusive, non-transferable right to use the content and
            materials on the Website in connection with your normal,
            noncommercial, use of the Website. You may not copy, reproduce,
            transmit, distribute, or create derivative works of such content or
            information without express written authorization from Ghumfir or
            the applicable third party (if third party content is at issue).{" "}
            <br /> <br />
            <span className={styles.bold_title}>Posting </span>
            <br /> By posting, storing, or transmitting any content on the
            Website, you hereby grant Ghumfir a perpetual, worldwide,
            non-exclusive, royalty-free, assignable, right and license to use,
            copy, display, perform, create derivative works from, distribute,
            have distributed, transmit and assign such content in any form, in
            all media now known or hereinafter created, anywhere in the world.
            Ghumfir does not have the ability to control the nature of the
            user-generated content offered through the Website. You are solely
            responsible for your interactions with other users of the Website
            and any content you post. Ghumfir is not liable for any damage or
            harm resulting from/by or interactions between users. Ghumfir
            reserves the right, but has no obligation, to monitor interactions
            between and among users of the Website and to remove any content
            Ghumfir deems objectionable.
            <br /> <br />
            <span className={styles.bold_title}>
              III. DISCLAIMER OF ASSURANCE
            </span>
            <br /> YOUR USE OF THIS WEBSITE AND/OR SERVICES ARE AT YOUR SOLE
            RISK. THE WEBSITE AND SERVICES ARE OFFERED ON AN "AS IS" AND "AS
            AVAILABLE" BASIS. Ghumfir EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            IMPLIED ASSURANCE OF MERCHANTABILITY FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT, OR
            ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT. ("SERVICES" INCLUDE
            TRIAL SERVICES.) WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
            Ghumfir MAKES NO ASSURANCE: THAT THE INFORMATION PROVIDED ON THIS
            WEBSITE IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY. THAT THE LINKS
            TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS ACCURATE,
            RELIABLE, COMPLETE, OR TIMELY. NO ADVICE OR INFORMATION, WHETHER
            ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE WILL CREATE ANY
            WARRANTY NOT EXPRESSLY STATED HEREIN. AS TO THE RESULTS THAT MAY BE
            OBTAINED FROM THE USE OF THE PRODUCTS OR THAT DEFECTS IN PRODUCTS
            WILL BE CORRECTED. REGARDING ANY SERVICES OR OBTAINED THROUGH THE
            WEBSITE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
            ASSURANCE, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.{" "}
            <br /> <br />
            <span className={styles.bold_title}>
              IV. LIMITATION OF LIABILITY
            </span>{" "}
            <br />
            Ghumfir ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN
            EQUITY, OR OTHWERWISE, WITH RESPECT TO THE WEBSITE CONTENT AND
            SERVICES AND/OR FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED
            TO THE INTEREST YOU FILL, FOR FUNCTIONS AND SERVICES VIA THE
            WEBSITE.Ghumfir WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS
            AGREEMENT OR THE PRODUCTS IN ANY MANNER, INCLUDING LIABILITIES
            RESULTING FROM (1) THE USE OR THE INABILITY TO USE THE WEBSITE
            CONTENT OR SERVICES; (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS
            OR CONTENT; (3) ANY SERVICES OR OBTAINED OR TRANSACTIONS ENTERED
            INTO THROUGH THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE. SOME
            JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
            FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE
            LIMITATIONS MAY NOT APPLY TO YOU.
            <br /> <br />
            <span className={styles.bold_title}>V. AGREEMENT TO BE BOUND</span>
            <br />
            By using this Website or ordering Products, you acknowledge that you
            have read and agree to be bound by this Agreement and all terms and
            conditions on this Website. <br /> <br />
            <span className={styles.bold_title}>VI. INDEMNIFICATION</span>{" "}
            <br />
            You will release, indemnify, defend and hold harmless [Ghumfir], and
            any of its contractors, agents, employees, officers, directors,
            shareholders, affiliates and assigns from all liabilities, claims,
            damages, costs and expenses, including reasonable attorneys' fees
            and expenses, of third parties relating to or arising out of (1)
            this Agreement or the breach of your assurance, representations and
            obligations under this Agreement; (2) the Website content or your
            use of the Website content; (3) the Services or your use of the
            Services; (4) any intellectual property or other proprietary right
            of any person or entity; (5) your violation of any provision of this
            Agreement; or (6) any information or data you supplied to Ghumfir.
            When Ghumfir is threatened with suit or sued by a third party,
            Ghumfir may seek written assurances from you concerning your promise
            to indemnify Ghumfir; your failure to provide such assurances may be
            considered by Ghumfir to be a material breach of this Agreement.
            Ghumfir will have the right to participate in any defense by you of
            a third-party claim related to your use of any of the Website
            content or Services, with counsel of Ghumfir choice at its expense.
            Ghumfir will reasonably cooperate in any defense by you of a
            third-party claim at your request and expense. You will have sole
            responsibility to defend Ghumfir against any claim, but you must
            receive Ghumfir prior written consent regarding any related
            settlement. The terms of this provision will survive any termination
            or cancellation of this Agreement or your use of the Website.
            <br /> <br /> <br />
            <span className={styles.bold_title}>VII. PRIVACY</span> <br />{" "}
            <br />
            Ghumfir believes strongly in protecting user privacy and providing
            you with notice of use of data. Please refer to Ghumfir privacy
            policy, incorporated by reference herein, that is posted on the
            Website. <br /> <br /> <br />
            <span className={styles.bold_title}>
              {" "}
              VIII. GENERAL{" "}
            </span> <br /> <br /> <br />
            Force Majeure. Ghumfir will not be deemed in default hereunder or
            held responsible for any cessation, interruption or delay in the
            performance of its obligations hereunder due to earthquake, flood,
            fire, storm, natural disaster, act of God, war, terrorism, armed
            conflict, labor strike, lockout, or boycott. Cessation of Operation.
            Ghumfir may at any time, in its sole discretion and without advance
            notice to you, cease operation of the Website. Entire Agreement.
            This Agreement comprises the entire agreement between you and
            Ghumfir and supersedes any prior agreements pertaining to the
            subject matter contained herein. Effect of Waiver. The failure of
            Ghumfir to exercise or enforce any right or provision of this
            Agreement will not constitute a waiver of such right or provision.
            If any provision of this Agreement is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties' intentions as
            reflected in the provision, and the other provisions of this
            Agreement remain in full force and effect. Governing Law;
            Arbitration. This Website originates from the Kathmandu, Bagmati.
            This Agreement will be governed by the laws of the State of
            Kathmandu Nepal without regard to its conflict of law principles to
            the contrary. Neither you nor Ghumfir will commence or prosecute any
            suit, proceeding or claim to enforce the provisions of this
            Agreement, to recover damages for breach of or default of this
            Agreement, or otherwise arising under or by reason of this
            Agreement, other than in courts located in State of Bagmati. By
            using this Website or ordering Products, you consent to the
            jurisdiction and venue of such courts in connection with any action,
            suit, proceeding or claim arising under or by reason of this
            Agreement. You hereby waive any right to trial by jury arising out
            of this Agreement and any related documents. Statute of Limitation.
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to use of the
            Website or Products or this Agreement must be filed within one (1)
            year after such claim or cause of action arose or be forever barred.
            Waiver of Class Action Rights. BY ENTERING INTO THIS AGREEMENT, YOU
            HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH
            THOSE OF OTHER IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL
            DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION WITH
            THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY. Termination. Ghumfir
            reserves the right to terminate your access to the Website if it
            reasonably believes, in its sole discretion, that you have breached
            any of the terms and conditions of this Agreement. Following
            termination, you will not be permitted to use the Website and
            Ghumfir may, in its sole discretion and without advance notice to
            you, cancel any outstanding orders for Products. If your access to
            the Website is terminated, Ghumfir reserves the right to exercise
            whatever means it deems necessary to prevent unauthorized access of
            the Website. This Agreement will survive indefinitely unless and
            until Ghumfir chooses, in its sole discretion and without advance to
            you, to terminate it. Domestic Use. Ghumfir makes no representation
            that the Website are appropriate or available for use in locations
            outside Nepal. Users who access the Website from outside Nepal do so
            at their own risk and initiative and must bear all responsibility
            for compliance with any applicable local laws. Assignment. You may
            not assign your rights and obligations under this Agreement to
            anyone. Ghumfir may assign its rights and obligations under this
            Agreement in its sole discretion and without advance notice to you.
            <span className={styles.bold_title}> Assignment. </span> <br />{" "}
            <br />
            You may not assign your rights and obligations under this Agreement
            to anyone. Ghumfir may assign its rights and obligations under this
            Agreement in its sole discretion and without advance notice to you.
            <br /> <br /> <br />
            BY USING THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND
            CONDITIONS OF THIS AGREEMENT.
          </p>
        </Modal.Body>
        <Modal.Footer bsPrefix="modal-footer"></Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonModal;
