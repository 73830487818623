import React, { useState } from "react";
import styles from "./signup.module.css";
import { Formik } from "formik";
import TextField from "../common/forms/TextField";
import { signupSchema } from "../../schemas/validation-schemas";
import { NavLink, useNavigate } from "react-router-dom";
import CheckboxField from "../common/forms/CheckboxField";
import axiosInstance from "../../services/axiosInstance";
import toast from "react-hot-toast";
import ReactLoader from "../../components/common/loader/ReactLoader";
import CommonModal from "../common/modal/CommonModal";
import "./signup.css";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    gender: "",
    has_18_agreement: false,
    has_agreed_tnc: false,
  };
  return (
    <>
      <div className={`${styles.signup_main_div}`}>
        <div className={`${styles.navbar_signup}`}></div>
        {loading && <ReactLoader />}
        <div className={`${styles.signup_container} container-fluid`}>
          <div className={`${styles.signup_main_row} row`}>
            <div className={`${styles.form_col} col-lg-6 col-md-6 col-sm-12`}>
              <h1 className={`${styles.form_title}`}>
                Find Your <br /> Companion
              </h1>

              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                  setLoading(true);

                  try {
                    const response = await axiosInstance.post(
                      "auth/users/",
                      {
                        ...values,
                        username: values.email,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    );

                    if (response.status === 201) {
                      setLoading(false);
                      resetForm();
                      navigate("/email-sent");
                      toast.success("Account created successfully");
                    }
                  } catch (err) {
                    if (err.response.status === 400) {
                      setLoading(false);
                      // toast.error(err.response.data?.email);
                      console.log("Error Response", err.response.data);
                      console.log("Error Responswwwwe", err);

                      toast.error("Something went wrong");
                      resetForm();
                    } else if (err.response.status === 409) {
                      setLoading(false);
                      toast.error("User already exists");
                      resetForm();
                    }
                  }
                }}
                validateOnMount
                validationSchema={signupSchema}
              >
                {(values) => {
                  return (
                    <div className={`${styles.form_div}`}>
                      <form onSubmit={values.handleSubmit} autoComplete="off">
                        <div className="row">
                          <div className={`form-group col-lg-6 col-md-6 mb-0`}>
                            <TextField
                              type="text"
                              name="first_name"
                              label="First Name *"
                            />
                          </div>
                          <div className={`form-group col-lg-6 col-md-6 mb-0`}>
                            <TextField
                              type="text"
                              name="last_name"
                              label="Last Name *"
                            />
                          </div>
                        </div>
                        <TextField type="email" name="email" label="Email *" />
                        <TextField
                          type="password"
                          name="password"
                          label="Password *"
                        />
                        <TextField
                          type="number"
                          name="mobile"
                          label="Mobile Number *"
                        />
                        <label className={`${styles.gender_label}`}>
                          Gender *
                        </label>
                        <div className={`${styles.form_radio_btn_div}`}>
                          <TextField
                            name="gender"
                            type="radio"
                            label="Male"
                            value="M"
                          />
                          <TextField
                            name="gender"
                            type="radio"
                            label="Female"
                            value="F"
                          />
                          <TextField
                            name="gender"
                            type="radio"
                            label="Other"
                            value="O"
                          />
                        </div>
                        <div
                          className={`form-check ${styles.form_checkbox_div}`}
                        >
                          <div className={`${styles.form_checkbox_div_signup}`}>
                            <CheckboxField
                              type="checkbox"
                              name="has_18_agreement"
                            />
                            <label
                              className={`form-check-label ${styles.form__signup_label}`}
                              for="flexCheckDefault"
                            >
                              I agree I am <b>above 18+</b>
                            </label>
                          </div>
                          <div>
                            <CheckboxField
                              type="checkbox"
                              name="has_agreed_tnc"
                            />
                            <label
                              className={`form-check-label ${styles.form__signup_label}`}
                              for="flexCheckDefault"
                            >
                              I agree to the
                              <NavLink
                                className={`${styles.terms_condition}`}
                                to=""
                                onClick={() => setModalShow(true)}
                              >
                                Terms and Conditions
                              </NavLink>
                              <CommonModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                              />
                            </label>
                          </div>
                        </div>

                        {/* <CheckboxField type="checkbox" name="has_18_agreement" /> */}
                        <button
                          className={`btn btn-primary  ${styles.submit_btn}`}
                          type="submit"
                          name="terms"
                          disabled={!values.isValid || loading}
                        >
                          Signup
                        </button>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            </div>

            <div
              className={`${styles.right_col_div} col-lg-6 col-md-6 col-sm-12`}
            >
              <div className={`${styles.right_col_img_div}`}>
                <img
                  className={`${styles.right_col_img}`}
                  src="/images/login-signup/photo5.jpg"
                  alt="group11"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
