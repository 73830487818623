import { Icon } from "@iconify/react";
import React from "react";
import styles from "./homeTestimonials.module.css";

const TestimonialUI = (props) => {
  const reviewPara = props.review.substring(0, 200);

  return (
    <div>
      <div className={`${styles.testimonial_card} card`}>
        <div
          className={`card-body ${styles.card_top_div} d-flex justify-content-between`}
        >
          <div className={`${styles.card_top_content} `}>
            <Icon
              icon="carbon:location-filled"
              className={`${styles.location_icon}`}
            />
            <span className={`${styles.location_title}`}>{props.location}</span>
          </div>
          <div className={styles.card_rating}>{props.rating}</div>
        </div>

        <div className={`${styles.card_body_content} card-text`}>
          <p className={styles.review_para}>{reviewPara}...</p>
        </div>
        <div className={`${styles.card_bottom_content}`}>
          <div className={`${styles.card_img_div}`}>
            <img
              className={`${styles.card_img}`}
              src={props.imageUrl}
              alt={props.name}
            />
          </div>
          <div className={`${styles.card_user_content}`}>
            <span className={`${styles.user_name}`}>{props.name}</span>
            <span className={`${styles.user_desp}`}>{props.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialUI;
