import React from "react";

const TermsAndCondition = () => {
  // const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <h1>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero
        veritatis deleniti hic necessitatibus fuga suscipit quibusdam fugiat cum
        repellendus? Unde aspernatur vero exercitationem! Distinctio omnis
        reiciendis vero non inventore facilis!
      </h1>
    </div>
  );
};

export default TermsAndCondition;
