import React from "react";
import styles from "./homeProject.module.css";

const ProjectUI = (props) => {
  return (
    <>
      <div className={`${styles.project_image_div}`}>
        <img
          className={`${styles.project_img}`}
          src={props.imageUrl}
          alt={props.name}
        />
      </div>
    </>
  );
};

export default ProjectUI;
