import React from "react";
import { transportList } from "./transportList";
import styles from "./homeTransport.module.css";
import HomeSectionHover from "../../common/hoverSection/HomeSectionHover";

const HomeTransport = () => {
  return (
    <div>
      <div className={`${styles.service_container} container pl-0`}>
        <div className={`row ${styles.home_transport_row}`}>
          {transportList?.map((transport, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`col-lg-4 col-md-4 col-sm-6 ${styles.home_transport_img_div}`}
                >
                  <HomeSectionHover imageUrl={transport?.imageUrl} />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeTransport;
