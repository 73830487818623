import React from "react";
import HomeTitle from "../../../pages/common/HomeTitle";
import Slider from "react-slick-slider";
import TestimonialUI from "./TestimonialUI";
import { testimonailList } from "./testimonialList";
import styles from "./homeTestimonials.module.css";
import "./slickStyle.css";

const HomeTestimonials = () => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 0,
  //   slidesToScroll: 1,
  // };
  let settings = {
    dots: false,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "1px",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    speed: 1000,
    margin: 20,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={`${styles.home_testimonial_section_container} container pl-0`}
    >
      <div className={`${styles.home_testimonial_section}`}>
        <HomeTitle title="Testimonials" subtitle="What Ghumfirers Say" />
        {/* <div className={`row ${styles.home_testimonial_row}`}> */}
        <Slider className={`row ${styles.home_testimonial_row}`} {...settings}>
          {testimonailList?.map((list) => {
            return (
              <div
                className={`col-lg-4 col-md-4 col-sm-6 ${styles.testimonial_div}`}
                key={list?.id}
              >
                <TestimonialUI
                  name={list?.name}
                  imageUrl={list?.imageUrl}
                  location={list?.address}
                  description={list?.desp}
                  rating={list?.rating}
                  review={list?.review}
                />
              </div>
            );
          })}
        </Slider>
        {/* </div> */}
      </div>
    </div>
  );
};

export default HomeTestimonials;
