import { Icon } from "@iconify/react";
import { Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { forgotPasswordSchema } from "../../schemas/forgot-password";
import axiosInstance from "../../services/axiosInstance";
import TextField from "../common/forms/TextField";
import styles from "./forgot.module.css";
const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  return (
    <div className={`${styles.forgot_main_div} container`}>
      <div className={`${styles.forgot_row} row`}>
        <div
          className={`${styles.forgot_col_left} col-lg-6 col-md-6 col-sm-12`}
        >
          <NavLink to="/login">
            <p className={`${styles.forgot_icon_title}`}>
              <Icon
                icon="akar-icons:chevron-left"
                className={`${styles.goback_icon}`}
              />
              Back To Login
            </p>
          </NavLink>
          <h1 className={`${styles.forgot_title}`}>Forgot Password ?</h1>
          <p className={`${styles.forgot_para}`}>
            Fear not. We will email you recovery link to reset your password.
          </p>

          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);
              try {
                const response = await axiosInstance.post(
                  "auth/users/reset_password/",
                  values
                );
                if (response.status === 204) {
                  resetForm();

                  navigate("/email-sent");
                  toast.success("Recovery link sent to your email");
                  setLoading(false);
                }
              } catch (err) {
                resetForm();
                navigate("/email-sent");
                toast.error("Something Went Wrong", err);
                setLoading(false);
              }
            }}
            validateOnMount
            validationSchema={forgotPasswordSchema}
          >
            {({ handleSubmit, isValid }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className={`${styles.forgot_form}`}
                >
                  <TextField type="text" name="email" label="Email" />
                  <button
                    type="submit"
                    disabled={!isValid || loading}
                    className={`btn ${styles.forgot_btn}`}
                  >
                    Send Recovery Email
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
        <div
          className={`${styles.forgot_col_right} col-lg-6 col-md-6 col-sm-12`}
        >
          <div className={`${styles.forgot_img_div}`}>
            <img
              src="/images/homepage/forgot.png"
              alt="forgot"
              className={`${styles.forgot_img}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
