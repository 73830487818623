import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/common/sidebar/Sidebar";
import styles from "./account.module.css";
// import { Outlet, Route, Routes } from "react-router-dom";

const Account = () => {
  return (
    <>
      <div className={`container-fluid p-0`}>
        <div className={`${styles.account_page_div}`}>
          <div className={`row m-0`}>
            <div className={`col-lg-3 col-md-4 col-sm-12`}>
              <Sidebar />
            </div>
            {/* <Routes>
            <Route path="/account" element={<Account />} />
          </Routes> */}
            <hr />
            <div className={`col-lg-9 col-md-9 col-sm-12`}>
              <Outlet />
            </div>
          </div>
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default Account;
