import React from "react";
import { useField } from "formik";
import styles from "./checkbox.module.css";
import "./checkbox.module.css";

const InterestCheckbox = (props) => {
  const [field] = useField({ name: props.name, type: props.type });
  return (
    <>
      <label
        className={` ${styles.checkbox_div_label} text-gray-500 font-bold`}
      >
        <input
          {...field}
          className={`${styles.checkbox_input} mr-2 leading-tight`}
          type="checkbox"
        />
        <span className={`${styles.checkbox_label} text-sm`}>
          {props.label}
        </span>
      </label>
    </>
  );
};

export default InterestCheckbox;
