import React from "react";
import styles from "./homeBanner.module.css";

const HomeBanner = () => {
  return (
    <>
      {/* <div className={`${styles.home_banner_section_container} container pl-0`}> */}
      <div className={`${styles.home_banner_section}`}>
        <div className={`row`}>
          {/* <div className={`col-lg-6 col-md-6 col-sm-6`}></div> */}
          <div className={`${styles.banner_container_div}`}>
            <div className={`${styles.banner_left} col-lg-4`}>
              <p className={styles.download_text}>Download Mobile App </p>
              <h3 className={styles.download_access_text}>
                Get access to your <br /> GHUMFIR wherever <br /> you are
              </h3>
              <div className={styles.banner_img_div}>
                <img
                  className={styles.banner_img_1}
                  src="/images/homepage/Banner/play_store.png"
                  alt="playstore"
                />
                <img
                  className={styles.banner_img_2}
                  src="/images/homepage/Banner/app_store.png"
                  alt="appstore"
                />
              </div>
            </div>
            <div className={`${styles.banner_right} col-lg-8`}>
              <img
                src="/images/homepage/Banner/mbl.png"
                alt="banner_img"
                className="banner_right_img"
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default HomeBanner;
