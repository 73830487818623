import React from "react";
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import styles from "./emailVerification.module.css";

const EmailVerification = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const handleNext = async () => {
    const response = await axiosInstance.post(
      "auth/users/activation/",
      { uid, token },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 204) {
      toast.success("Your account has been activated successfully");
      navigate("/login");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className={`${styles.email_verification_div}`}>
      <img
        src="/images/homepage/emailVerification.png"
        alt="email_verification"
        className={`${styles.email_verification_img}`}
      />
      <p className={styles.verication_text}>Welcome to </p>
      <img
        className={styles.ghumfir_logo}
        src="/images/logo_dark.png"
        alt="email_verification"
      />
      <h3 className={styles.verication_title}>
        Please continue verfication process for account activation.
      </h3>

      <button className={`${styles.next_btn} btn`} onClick={handleNext}>
        Verify
      </button>
    </div>
  );
};

export default EmailVerification;
