import React from "react";
import { Carousel } from "react-bootstrap";
import styles from "./carousel.module.css";

const CarouselComponent = () => {
  return (
    <div>
      <Carousel indicators={false} controls={false} fade interval={3000}>
        <Carousel.Item className={`${styles.carousel_item_div}`}>
          <img
            className={`${styles.carousel_item_img}`}
            src="/images/homepage/group1.png"
            alt="group1"
          />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carousel_item_div}`}>
          <img
            className={`${styles.carousel_item_img}`}
            src="/images/homepage/group2.jpg"
            alt="group2"
          />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carousel_item_div}`}>
          <img
            className={`${styles.carousel_item_img}`}
            src="/images/homepage/group3.jpg"
            alt="group3"
          />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carousel_item_div}`}>
          <img
            className={`${styles.carousel_item_img}`}
            src="/images/homepage/group4.jpg"
            alt="group3"
          />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carousel_item_div}`}>
          <img
            className={`${styles.carousel_item_img}`}
            src="/images/homepage/group5.jpg"
            alt="group3"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
