import React from "react";
import styles from "./footer.module.css";
import { Icon } from "@iconify/react";
import Copyright from "../components/Copyright/Copyright";

const FooterGhumfir = () => {
  return (
    <>
      <div className={`container-fluid ${styles.footer_container_fluid} p-0`}>
        <div className={`container ${styles.footer_container}`}>
          <div className={`row`}>
            <div className={`col-lg-3 ma_t p-0`}>
              <h2 className={`${styles.footer_title}`}>TEAMS</h2>
              <ul className={`p-0`}>
                <li>Bibesh Paudel</li>
                <li>Deepson Shrestha</li>
                <li>Saugat Ghimire</li>
                <li>Saugat Maharjan</li>
                <li>Subash Pandey</li>
              </ul>
            </div>
            <div className={`col-lg-3`}>
              <h2 className={`${styles.footer_title}`}>SERVICES</h2>
              <ul className={`p-0`}>
                <li>Comapnion Finder</li>
                <li>Hotel Booking</li>
                <li>Ticket Booking</li>
              </ul>
            </div>
            <div className={`col-lg-4`}>
              <h2 className={`${styles.footer_title}`}>CONTACT</h2>
              <ul className={`p-0`}>
                <li>
                  <Icon
                    className={`${styles.footer_icon}`}
                    icon="akar-icons:location"
                  />
                  Deepjyoti Ground Rd, Kathmandu
                </li>
                <li>
                  <Icon
                    className={`${styles.footer_icon}`}
                    icon="ant-design:mail-outlined"
                  />{" "}
                  wezettas@gmail.com
                </li>
                <li>
                  <Icon className={`${styles.footer_icon}`} icon="bx:phone" />{" "}
                  +977 9808039867
                </li>
              </ul>
            </div>
            <div className={`col-lg-2 p-0 ${styles.map_div}`}>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.2682322953174!2d85.30543131457497!3d27.739871530669774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19f33b2a7b81%3A0xe8c80f50f28d23f9!2sZETTAMINDS!5e0!3m2!1sen!2snp!4v1661140795628!5m2!1sen!2snp"
                width="230"
                height="150"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default FooterGhumfir;
