export const transportList = [
  {
    id: 1,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/1.jpg",
    name: "Hyatt Regency",
    rating: "⭐⭐⭐⭐",
    address: "Kathmandu",
    price: "NPR 11,500",
  },
  {
    id: 2,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/2.jpg",
    name: "Temple Tree Resort",
    rating: "⭐⭐⭐",
    address: "Pokhara",
    price: "NPR 9,500",
  },
  {
    id: 3,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/3.jpg",
    name: "Moksha",
    rating: "⭐⭐⭐⭐",
    address: "Mustang",
    price: "NPR 8,500",
  },
  {
    id: 4,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/4.jpg",
    name: "Snow Dream",
    rating: "⭐⭐⭐⭐",
    address: "Kalinchwok",
    price: "NPR 10,500",
  },
  {
    id: 5,
    icon: "",
    imageUrl: "/images/homepage/Transport/5.jpg",
    name: "",
    rating: "",
    address: "",
    price: "",
  },
  {
    id: 6,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/1.jpg",
    name: "Dwarika Hotel",
    rating: "⭐⭐⭐⭐",
    address: "Kathmandu",
    price: "NPR 7,500",
  },
  {
    id: 7,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/2.jpg",
    name: "KGH",
    rating: "⭐⭐⭐⭐",
    address: "Kathmandu",
    price: "NPR 12,500",
  },
  {
    id: 8,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/3.jpg",
    name: "Glacier Hotel & Spa",
    rating: "⭐⭐⭐⭐",
    address: "Pokhara",
    price: "NPR 9,500",
  },
  {
    id: 9,
    icon: "fa-solid:bed",
    imageUrl: "/images/homepage/Transport/4.jpg",
    name: "Fish Tail Lodge",
    rating: "⭐⭐⭐⭐",
    address: "Pokhara",
    price: "NPR 7,500",
  },
];
