import React from "react";
import HomeTitle from "../../../pages/common/HomeTitle";
import styles from "./homeService.module.css";
import HomeServiceUI from "./HomeServiceUI";
import { serviceList } from "./serviceList";

const HomeServices = () => {
  return (
    <div className={`${styles.service_container} container pl-0`}>
      <div className={`${styles.home_service_title_div}`}>
        <HomeTitle title="Amazing" subtitle="Services" />
      </div>

      <div className={`row ${styles.home_service_row}`}>
        {serviceList?.map((list, index) => {
          return (
            <>
              <div
                key={index}
                className={`col-lg-4 col-md-4 col-sm-6 ${styles.home_service_img_div}`}
              >
                <HomeServiceUI
                  name={list.name}
                  imageUrl={list.imageUrl}
                  address={list.address}
                  icon={list.icon}
                  price={list.price}
                  rating={list.rating}
                />
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default HomeServices;
