import React from "react";

const ProfilePreview = ({ file }) => {
  const [preview, setPreview] = React.useState(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };

  return (
    <div>
      {preview ? (
        <img
          src={preview}
          alt="preview"
          style={{
            paddingTop: "10px",
            width: "160px",
            height: "160px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ) : (
        "Uploading Image"
      )}
    </div>
  );
};

export default ProfilePreview;
