import React from "react";
import styles from "./Card.module.css";
const Card = (props) => {
  return (
    <div className={`${styles.card_div}`}>
      <div className={`${styles.user_img_div}`}>
        <img
          className={`${styles.user_img}`}
          src={props.image}
          alt="user_img"
        />
      </div>
      <div className={`${styles.user_name_div}`}>
        <h1 className={`${styles.user_name}`}>{props.name}</h1>
      </div>
      <div className={`${styles.user_info_div}`}>
        <p className={`${styles.user_email}`}>{props.email}</p>
      </div>
      <div className={`${styles.user_address_div}`}>
        <p className={`${styles.user_address}`}>{props.address}</p>
      </div>
      <div className={`${styles.user_age_div}`}>
        <p className={`${styles.user_age}`}>{props.age}</p>
      </div>
      <div className={`${styles.user_btn_div}`}>
        <button
          className={`btn btn-primary  ${styles.submit_btn}`}
          type="submit"
          name="terms"
        >
          Interested
        </button>
      </div>
    </div>
  );
};

export default Card;
