import React, { useState } from "react";
import CarouselComponent from "../../components/CarouselContainer/CarouselComponent";
import HomeBanner from "../../components/HomeComponents/homeBanner/HomeBanner";
import HomeIntro from "../../components/HomeComponents/introSection/HomeIntro";
import HomeProject from "../../components/HomeComponents/projectSection/HomeProject";
import HomeServices from "../../components/HomeComponents/serviceSection/HomeServices";
import HomeTestimonials from "../../components/HomeComponents/testimonialSection/HomeTestimonials";
import HomeTransport from "../../components/HomeComponents/transportSection/HomeTransport";
import HomeTrending from "../../components/HomeComponents/trendingSection/HomeTrending";
import PlanTripModal from "../common/modal/PlanTripModal";
import styles from "./home.module.css";

const HomePage = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className={`${styles.home_carousel_div}`}>
        <CarouselComponent />
        <div className={`${styles.hero_title_div}`}>
          <p className={`${styles.hero_title}`}>
            WHERE YOU <br /> FIND COMPANION!
          </p>
        </div>
        <div className={`${styles.hero_para_div}`}>
          <p className={`${styles.hero_para}`}>
            Connect with fellow companion of similar taste and have some
            exciting trips. Exciting people, Adventurous places, fun memories.
          </p>
        </div>
        <div className={`${styles.hero_btn_div}`}>
          <button
            onClick={() => setModalShow(true)}
            value="Plan a Trip"
            className={`${styles.hero_btn_start}`}
          >
            Plan a trip
            {/* <button className={`${styles.hero_btn_learn_more}`}>
            Learn More
          </button> */}
          </button>
          <PlanTripModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* Home Intro Section Start */}

      <div className={`container-fluid ${styles.home_intro_container_fluid}`}>
        <HomeIntro />
      </div>

      {/* Home Intro Section End */}

      {/* Home Trending Section Start */}

      <div
        className={`container-fluid ${styles.home_trending_container_fluid}`}
      >
        <HomeTrending />
      </div>

      {/* Home Trending Section End */}

      {/* Home Service Section Start */}
      <div className={`${styles.home_service_container_fluid} container-fluid`}>
        <HomeServices />
      </div>

      {/* Home Service Section End */}

      {/* Home Service Transport Section Start */}

      <div
        className={`${styles.home_transport_container_fluid} container-fluid`}
      >
        <HomeTransport />
      </div>

      {/* Home Service Transport Section End */}

      {/* Home Testimonials Section Start */}

      <div
        className={`${styles.home_testimonial_container_fluid} container-fluid`}
      >
        <HomeTestimonials />
      </div>

      {/* Home Testimonials Section End */}

      {/* Home Project Section Start */}

      <div className={`${styles.home_project_container_fluid} container-fluid`}>
        <HomeProject />
      </div>

      {/* Home Project Section End */}

      {/* Home Banner Section Start */}
      <div className={`${styles.home_banner_container_fluid} container-fluid`}>
        <HomeBanner />
      </div>

      {/* Home Banner Section End */}
    </>
  );
};

export default HomePage;
