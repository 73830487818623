import React from "react";
import { useField } from "formik";
import styles from "./form.module.css";

const CheckboxField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {/* <label className={`${styles.form_label}`}>{label}</label> */}
      <div className={`form-check ${styles.form_checkbox_main_div}`}>
        <input
          className={`form-check-input ${styles.form_checkbox_field}`}
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <>
          <small className={`${styles.form_errors} form-text`}>
            {meta.error}
          </small>
        </>
      ) : null}
    </>
  );
};

export default CheckboxField;
