const cardList = [
  {
    id: 1,
    imgUrl: "/images/homepage/Testimonials/subash.jpg",
    address: "Pokhara",
    name: "Subash",
    age: "24",
    email: "subash.171442@ncit.edu.np",
  },
  {
    id: 2,
    imgUrl: "/images/homepage/Testimonials/taku.jpg",
    address: "Kanyam",
    name: "Deepson",
    age: "24",
    email: "deepson@ncit.edu.np",
  },
  {
    id: 3,
    imgUrl: "/images/homepage/Testimonials/puntu.jpg",
    address: "Chitwan",
    name: "Bibesh",
    age: "24",
    email: "bibesh@ncit.edu.np",
  },
  {
    id: 4,
    imgUrl: "/images/homepage/Testimonials/saugat.jpg",
    address: "Chitwan",
    name: "Saugat",
    age: "24",
    email: "saugat@ncit.edu.np",
  },
];

export default cardList;
