import React from "react";
import { interestList } from "./interestList";
import styles from "./yourInterest.module.css";

const YourInterest = () => {
  return (
    <div>
      <div className={`${styles.interest_div}`}>
        <h1 className={`${styles.interest_title}`}>Your Interest</h1>
        <div className={`${styles.interest_container} container`}>
          <div className={`row p-0 ${styles.interest_row}`}>
            {interestList.map((interest) => {
              return (
                <div
                  className={`col-lg-2 col-md-2 col-sm-6 ${styles.interest_column}`}
                >
                  <div className={`${styles.interest_card}`}>
                    <input type="checkbox" id={interest.id} />
                    <label for={interest.id}>
                      <img
                        className={`${styles.interest_img}`}
                        src={interest.imageUrl}
                        alt="interest"
                      />
                    </label>
                    <h3 className={`${styles.interest_name}`}>
                      {interest.name}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourInterest;
