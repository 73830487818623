const cardList2 = [
  {
    id: 1,
    imgUrl: "/images/homepage/Testimonials/taku.jpg",
    address: "Kanyam",
    name: "Deepson",
    age: "24",
    email: "deepson@ncit.edu.np",
  },
  {
    id: 2,
    imgUrl: "/images/homepage/Testimonials/saugat.jpg",
    address: "Chitwan",
    name: "Saugat",
    age: "24",
    email: "saugat@ncit.edu.np",
  },
];

export default cardList2;
