import React from "react";
import styles from "./email.module.css";

const EmailSent = () => {
  return (
    <div className={`${styles.email_sent_div}`}>
      <img
        src="/images/homepage/emailSent.png"
        className={`${styles.email_sent_img}`}
        alt="message-box"
      />
      <h1 className={`${styles.email_sent_h1}`}>
        A verification email has been sent to your email
      </h1>
      <p className={`${styles.email_sent_para}`}>
        Please check your email and verify and activate your account to proceed
        futher. The link in email will expire in 24 hours.
      </p>
    </div>
  );
};

export default EmailSent;
