import React from "react";
import styles from "./homeintro.module.css";

const HomeIntro = () => {
  return (
    <>
      <div className={`container ${styles.home_intro_container}`}>
        <h3 className={`${styles.home_intro_title}`}>
          LET’S ENJOY AND TRAVEL WITH YOUR RECOMMENDED COMPANION
        </h3>
        <p className={`${styles.home_intro_para}`}>
          We offer variety of tours and destinations to all kind of ghumfirer’s
          based on the recommended interest. Ghumfir user can enjoy exlusive
          deals and tours along with their matched companions.
        </p>
        <div className={`${styles.gallary_main_div}`}>
          <div className="row">
            <div
              className={`${styles.gallary_item_1} col-lg-4 col-md-4 col-sm-12`}
            >
              <h2 className={`${styles.gallary_title}`}>Nature Tour</h2>
              <p className={`${styles.gallary_para}`}>
                Our aim is to provide ghumfirers with the tour service of the
                top quality, and we provide yo find suitable tour by
                recommending with the taste of similar users. Our aim is to
                provide ghumfirers with the tour service of the top quality, and
                we provide yo find suitable tour by recommending with the taste
                of similar users.Our aim is to provide ghumfirers with the tour
                service of the top quality, and we provide yo find suitable tour
                by recommending with the taste of similar users.
              </p>
              <button className={`${styles.gallary_btn}`}>Learn More</button>
            </div>
            <div
              className={`${styles.gallary_item_2} col-lg-4 col-md-4 col-sm-12 p-0`}
            >
              <img
                className={`${styles.gallary_img_2}`}
                src="/images/homepage/Gallary/2.jpg"
                alt="home_image"
              />
            </div>
            <div
              className={`${styles.gallary_item_3} col-lg-4 col-md-4 col-sm-12 p-0`}
            >
              <img
                className={`${styles.gallary_img_3}`}
                src="/images/homepage/Gallary/4.jpg"
                alt="home_image"
              />
            </div>
          </div>
          <div className="row">
            <div
              className={`${styles.gallary_item_4} col-lg-4 col-md-4 col-sm-12 p-0`}
            >
              <img
                className={`${styles.gallary_img_4}`}
                src="/images/homepage/Gallary/3.jpg"
                alt="home_image"
              />
            </div>
            <div
              className={`${styles.gallary_item_5} col-lg-4 col-md-4 col-sm-12 p-0`}
            >
              <img
                className={`${styles.gallary_img_5}`}
                src="/images/homepage/Gallary/1.jpg"
                alt="home_image"
              />
            </div>
            <div
              className={`${styles.gallary_item_6} col-lg-4 col-md-4 col-sm-12`}
            >
              <h2 className={`${styles.gallary_title}`}>Exclusive Deals</h2>
              <p className={`${styles.gallary_para}`}>
                Our mission is to match the user with similar interest and
                taste. All our tours and excursions are available and
                recommended accordingly at really affordable prices so you can
                always pick a great destination. Our mission is to match the
                user with similar interest and taste. All our tours and
                excursions are available and recommended accordingly at really
                affordable prices so you can always pick a great destination.
              </p>
              <button className={`${styles.gallary_btn}`}>View All</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeIntro;
