import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "../../services/axiosInstance";
import InterestCheckbox from "../common/forms/InterestCheckbox";
import InterestedRadio from "../common/forms/InterestedRadio";
import styles from "./interest.module.css";

const InterestPage = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [interestData, setInterestData] = useState(null);

  const initialValues = {
    male: false,
    any: false,
    female: false,
    meditation_and_yoga: false,
    rafting: false,
    partying: false,
    driving: false,
    camping: false,
    cooking: false,
    hiking: false,
    biking: false,
    sight_seeing: false,
    religious: false,
    budget: "",
    age: "",
    user: "",
  };

  useEffect(() => {
    const InterestResponse = async () => {
      try {
        const response = await axiosInstance.get(`interest/${userInfo?.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        console.log("Interest Response", response.data);
        setInterestData(response?.data);
      } catch (err) {
        console.log("Console Error", err);
      }
    };

    InterestResponse();
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={`${styles.interest_container_fluid} container-fluid`}>
        <div className={`${styles.interest_row} row`}>
          <div className={`col-lg-12 ${styles.interest_col_div}`}>
            <div className={`${styles.interest_title_div}`}>
              <h1 className={`${styles.interest_title}`}>My Interests</h1>
            </div>
            <Formik
              initialValues={interestData || initialValues}
              onSubmit={async (data, { resetForm }) => {
                // console.log("Submit data", data);
                // alert(JSON.stringify(data, null, 2));
                try {
                  const response = await axiosInstance.post(
                    `interest/`,
                    {
                      rafting: data.rafting,
                      meditation_and_yoga: data.meditation_and_yoga,
                      partying: data.partying,
                      driving: data.driving,
                      camping: data.camping,
                      cooking: data.cooking,
                      hiking: data.hiking,
                      biking: data.biking,
                      sight_seeing: data.sight_seeing,
                      religious: data.religious,
                      user: userInfo?.id,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${localStorage.getItem("token")}`,
                      },
                    }
                  );

                  if (response.status === 201) {
                    toast.success(
                      "Your interest has been submitted successfully"
                    );
                    resetForm();
                  }
                } catch (error) {
                  toast.error(error);
                  resetForm();
                }
              }}
              enableReinitialize
            >
              {({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={`${styles.interest_div}`}>
                      {/* Gender Preference */}
                      <div className={`${styles.interest_gender_row}`}>
                        <div className={`${styles.interest_gender_div}`}>
                          <h1 className={`${styles.interest_gender_title}`}>
                            Gender Preference
                            <span className={`${styles.gender_subtitle}`}>
                              ( Both gender can be selected. )
                            </span>
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="male"
                                type="checkbox"
                                label="Male"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="female"
                                type="checkbox"
                                label="Female"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="any"
                                type="checkbox"
                                label="Any"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Select your Interest */}
                      <div className={`${styles.interest_selection_row}`}>
                        <div className={`${styles.interest_selection_div}`}>
                          <h1 className={`${styles.interest_selection_title}`}>
                            Select your Interest
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6 `}
                            >
                              <InterestCheckbox
                                name="rafting"
                                type="checkbox"
                                label="Rafting"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="sight_seeing"
                                type="checkbox"
                                label="Sight-Seeing"
                              />
                            </div>
                            {/* <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="dancing"
                                type="checkbox"
                                label="Dancing"
                              />
                            </div> */}
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="partying"
                                type="checkbox"
                                label="Partying"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="cooking"
                                type="checkbox"
                                label="Cooking"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="driving"
                                type="checkbox"
                                label="Driving"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="hiking"
                                type="checkbox"
                                label="Hiking"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="meditation_and_yoga"
                                type="checkbox"
                                label="Meditation & Yoga"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="religious"
                                type="checkbox"
                                label="Pilgrimage"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="camping"
                                type="checkbox"
                                label="Camping"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestCheckbox
                                name="biking"
                                type="checkbox"
                                label="Biking"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Prefered Budget Level */}

                      <div className={`${styles.interest_budget_row}`}>
                        <div className={`${styles.interest_budget_div}`}>
                          <h1 className={`${styles.interest_budget_title}`}>
                            Prefered Budget Level
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="budget"
                                type="radio"
                                value="B10"
                                label="Below 10K"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="budget"
                                type="radio"
                                value="B20"
                                label="Below 15K"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="budget"
                                type="radio"
                                value="B30"
                                label="Below 20K"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Prefered Age Group */}

                      <div className={`${styles.interest_age_row}`}>
                        <div className={`${styles.interest_age_div}`}>
                          <h1 className={`${styles.interest_age_title}`}>
                            Prefered Age Group
                          </h1>
                          <div className={`${styles.interest_field_div} row`}>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="age"
                                type="radio"
                                value="b-22"
                                label="18-22"
                              />
                            </div>

                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="age"
                                type="radio"
                                value="b-26"
                                label="23-26"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-2 col-md- col-sm-6`}
                            >
                              <InterestedRadio
                                name="age"
                                type="radio"
                                value="b-30"
                                label="27-30"
                              />
                            </div>
                            <div
                              className={`${styles.interest_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="age"
                                type="radio"
                                value="b30"
                                label="30+"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Button */}

                      <div className={`${styles.interest_btn_row}`}>
                        <button
                          type="submit"
                          className={`btn ${styles.save_change_btn}`}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestPage;
