// import { Icon } from "@iconify/react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "../../services/axiosInstance";
import CustomSelect from "../common/forms/CustomSelect";
// import InterestedRadio from "../common/forms/InterestedRadio";
import TextField from "../common/forms/TextField";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import styles from "./kyc.module.css";

const KycForm = () => {
  const [date, setDate] = useState("");
  const [savedData, setSavedData] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  console.log("User info", userData?.id);

  const handleDate = ({ bsDate, adDate }) => {
    setDate({ dob_bs: bsDate });
  };

  console.log("Nepali Date", date?.dob_bs);

  const initialValues = {
    first_name: "",
    last_name: "",
    dob_bs: date?.dob_bs,
    dob_ad: "",
    father_fullname: "",
    mother_fullname: "",
    permanent_address: "",
    current_address: "",
    education: "",
    occupation: "",
    citizenship_number: "",
    citizenship_issued_district: "",
    issue_date: "",
    is_verified: false,
    note: "ok",
    kyc_pp: "",
    citizenship_photo: "",
    card_number: "",
    card_issue_date: "",
    card_expiry_date: "",
    card_cvv: "",
    user: userData?.id,
  };

  useEffect(() => {
    const kycResponse = async () => {
      try {
        const response = await axiosInstance.get(`kyc/${userData?.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        });
        console.log("Kyc Response", response.data);
        setSavedData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    kycResponse();
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={`${styles.kyc_container_fluid} container-fluid`}>
        <div className={`${styles.kyc_row} row`}>
          <div className={`col-lg-12 ${styles.kyc_form_div}`}>
            <div className={`${styles.kyc_form_title_div}`}>
              <h1 className={`${styles.kyc_title}`}>KYC Form</h1>
            </div>
            <div className={`${styles.kyc_body_div}`}>
              <Formik
                initialValues={savedData || initialValues}
                onSubmit={async (values, { resetForm }) => {
                  let data = new FormData();
                  data.append("first_name", values.first_name);
                  data.append("last_name", values.last_name);
                  data.append("dob_bs", values.dob_bs);
                  data.append("dob_ad", values.dob_ad);
                  data.append("father_fullname", values.father_fullname);
                  data.append("mother_fullname", values.mother_fullname);
                  data.append("permanent_address", values.permanent_address);
                  data.append("current_address", values.current_address);
                  data.append("education", values.education);
                  data.append("occupation", values.occupation);
                  data.append("citizenship_number", values.citizenship_number);
                  data.append("issue_date", values.issue_date);
                  data.append(
                    "citizenship_issued_district",
                    values.citizenship_issued_district
                  );
                  data.append("is_verified", values.is_verified);
                  data.append("note", values.note);
                  data.append("kyc_pp", values.kyc_pp);
                  data.append("citizenship_photo", values.citizenship_photo);
                  data.append("card_number", values.card_number);
                  data.append("card_issue_date", values.card_issue_date);
                  data.append("card_expiry_date", values.card_expiry_date);
                  data.append("card_cvv", values.card_cvv);
                  data.append("user", values.user);

                  try {
                    const response = await axiosInstance.post("kyc/", data, {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${localStorage.getItem("token")}`,
                      },
                    });
                    if (response === 201) {
                      toast.success("KYC form submitted successfully");
                      resetForm();
                    }
                  } catch (err) {
                    toast.error("Error", err);
                  }

                  // console.log("Values of data", values);
                  // console.log("Values of data", values.date.dob_bs);
                }}
                enableReinitialize
              >
                {(values) => {
                  return (
                    <form
                      onSubmit={values.handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className={`${styles.kyc_personal_detail_div}`}>
                        <h1 className={`${styles.personal_detail_title}`}>
                          Personal Details
                        </h1>
                        <div
                          className={`form-group ${styles.input_field_div_half}`}
                        >
                          <div className="row">
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="text"
                                name="first_name"
                                label="First Name *"
                              />
                            </div>
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="text"
                                name="last_name"
                                label="Last Name *"
                              />
                            </div>
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <label
                                className={`${styles.calendar_field_label}`}
                              >
                                Date of Birth ( B.S ) *
                              </label>
                              <Calendar
                                onChange={handleDate}
                                className={`${styles.calendar_field} form-control `}
                                theme="deepdark"
                                language="en"
                              />
                            </div>
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="date"
                                name="dob_ad"
                                label="Date of Birth ( A.D ) *"
                              />
                            </div>
                          </div>

                          {/* Checkboxed */}

                          {/* <h1 className={`${styles.gender_title}`}>Gender</h1>
                          <div className={`${styles.kyc_gender_div} row`}>
                            <div
                              className={`${styles.gender_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="gender"
                                type="radio"
                                value="M"
                                label="Male"
                              />
                            </div>

                            <div
                              className={`${styles.gender_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="gender"
                                type="radio"
                                value="F"
                                label="Female"
                              />
                            </div>
                            <div
                              className={`${styles.gender_fields} col-lg-3 col-md-3 col-sm-6`}
                            >
                              <InterestedRadio
                                name="gender"
                                type="radio"
                                value="O"
                                label="Other"
                              />
                            </div>
                          </div> */}

                          <div
                            className={`form-group ${styles.input_field_div}`}
                          >
                            <TextField
                              type="text"
                              name="father_fullname"
                              label="Father's Full Name *"
                            />
                          </div>
                          <div
                            className={`form-group ${styles.input_field_div}`}
                          >
                            <TextField
                              type="text"
                              name="mother_fullname"
                              label="Mother's Full Name *"
                            />
                          </div>
                          <div className="row">
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="text"
                                name="permanent_address"
                                label="Permanent Address *"
                              />
                            </div>
                            <div
                              className={`form-group col-lg-6 col-md-6 mb-0`}
                            >
                              <TextField
                                type="text"
                                name="current_address"
                                label="Current Address *"
                              />
                            </div>
                          </div>
                          <div className={`${styles.kyc_education_detail_div}`}>
                            <h1 className={`${styles.education_detail_title}`}>
                              Education & Profession
                            </h1>
                            <div
                              className={`form-group ${styles.input_field_div}`}
                            >
                              <CustomSelect
                                name="education"
                                label="Education Level*"
                              >
                                <option value="">
                                  Select an education level{" "}
                                </option>
                                <option value="H">High Schools</option>
                                <option value="B">Bachelors</option>
                                <option value="M">Masters</option>
                              </CustomSelect>
                            </div>

                            <div
                              className={`form-group ${styles.input_field_div}`}
                            >
                              <TextField
                                type="text"
                                name="occupation"
                                label="Occupation *"
                                placeholder="Occupation"
                              />
                            </div>
                          </div>

                          <div className={`${styles.kyc_document_detail_div}`}>
                            <h1 className={`${styles.education_detail_title}`}>
                              Documents
                            </h1>
                            <div
                              className={`form-group ${styles.input_field_div}`}
                            >
                              <TextField
                                type="number"
                                name="citizenship_number"
                                label="Citizenship Number *"
                              />
                            </div>

                            <div
                              className={`form-group ${styles.input_field_div}`}
                            >
                              <TextField
                                type="text"
                                name="citizenship_issued_district"
                                label="Citizenship Issued District *"
                              />
                            </div>
                          </div>

                          <div
                            className={`form-group ${styles.input_field_div}`}
                          >
                            <TextField
                              type="date"
                              name="issue_date"
                              label="Citizenship Issued Date *"
                            />
                          </div>
                          {/* <label>Supported Document Photos</label> */}
                          {/* <div
                              className={`form-group col-lg-5 col-md-5 mb-0 ${styles.upload_file_div}`}
                            >
                              {values.values.kyc_pp ? (
                                <>
                                  <KycCitizen file={values.values.file} />
                                </>
                              ) : (
                                <img
                                  src="/images/kyc/kyc.png"
                                  alt="profile_image"
                                  className={`${styles.pp_image}`}
                                />
                              )}
                              <input
                                type="file"
                                ref={fileRef}
                                name="kyc_pp"
                                hidden
                                multiple
                                onChange={(event) => {
                                  values.setFieldValue(
                                    "kyc_pp",
                                    event.target.files[0]
                                  );
                                }}
                              />
                              {values.errors.file && (
                                <small style={{ color: "red" }}>
                                  *{values.errors.file}{" "}
                                </small>
                              )}
                              <div
                                onClick={() => {
                                  fileRef.current.click();
                                }}
                                className={`${styles.change_div}`}
                              >
                                <Icon
                                  icon={"bx:upload"}
                                  className={`${styles.edit_icon}`}
                                />
                                <span className={`${styles.change_text}`}>
                                  Upload PP Photo
                                </span>
                              </div>
                            </div>

                            <div
                              className={`form-group col-lg-5 col-md-5 mb-0 ${styles.upload_file_div}`}
                            >
                              {values.values.file ? (
                                <>
                                  <KycCitizen file={values.values.file} />
                                </>
                              ) : (
                                <img
                                  src="/images/kyc/kyc.png"
                                  alt="profile_image"
                                  className={`${styles.citizenship_image}`}
                                />
                              )}
                              <input
                                type="file"
                                ref={fileRef}
                                name="citizenship_photo"
                                hidden
                                multiple
                                onChange={(event) => {
                                  values.setFieldValue(
                                    "citizenship_photo",
                                    event.target.files[0]
                                  );
                                }}
                              />
                              {values.errors.file && (
                                <small style={{ color: "red" }}>
                                  *{values.errors.file}{" "}
                                </small>
                              )}
                              <div
                                onClick={() => {
                                  fileRef.current.click();
                                }}
                                className={`${styles.change_div}`}
                              >
                                <Icon
                                  icon={"bx:upload"}
                                  className={`${styles.edit_icon}`}
                                />
                                <span className={`${styles.change_text}`}>
                                  Upload Citizenship Photo
                                </span>
                              </div>
                            </div> */}
                          <div
                            className={`form-group ${styles.input_field_div}`}
                          >
                            {/* <label className={`${styles.photo_label}`}>
                              Upload Passport Size Photo
                            </label> */}
                            <TextField
                              type="file"
                              name="kyc_pp"
                              // style={{ display: "none" }}
                              label="Upload Passport Size Photo"
                              value={""}
                              onfocus="(this.type='file')"
                              multiple
                              onChange={(event) => {
                                values.setFieldValue(
                                  "kyc_pp",
                                  event.target.files[0]
                                );
                              }}
                            />
                          </div>
                          <div
                            className={`form-group ${styles.input_field_div}`}
                          >
                            {/* <label className={`${styles.photo_label}`}>
                              Upload Citizenship Photo
                            </label> */}
                            <TextField
                              type="file"
                              name="citizenship_photo"
                              // style={{ display: "none" }}
                              label="Upload Citizenship Photo"
                              value={""}
                              onfocus="(this.type='file')"
                              multiple
                              onChange={(event) => {
                                values.setFieldValue(
                                  "citizenship_photo",
                                  event.target.files[0]
                                );
                              }}
                            />
                          </div>

                          <div className={`${styles.kyc_card_detail_div}`}>
                            <h3 className={`${styles.education_detail_title}`}>
                              Card Information ( Debit / Credit )
                            </h3>

                            <div className="row">
                              <div
                                className={`form-group col-lg-6 col-md-6 mb-0`}
                              >
                                <TextField
                                  type="number"
                                  name="card_number"
                                  label="Card Number *"
                                />
                              </div>
                              <div
                                className={`form-group col-lg-6 col-md-6 mb-0`}
                              >
                                <TextField
                                  type="number"
                                  name="card_cvv"
                                  label="CVV*"
                                />
                              </div>
                              <div
                                className={`form-group col-lg-6 col-md-6 mb-0`}
                              >
                                <TextField
                                  type="date"
                                  name="card_issue_date"
                                  label="Issued Date *"
                                />
                              </div>
                              <div
                                className={`form-group col-lg-6 col-md-6 mb-0`}
                              >
                                <TextField
                                  type="date"
                                  name="card_expiry_date"
                                  label="Expiry Date *"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${styles.kyc_button_div}`}>
                        <button
                          type="submit"
                          className={`btn ${styles.save_change_btn}`}
                          // disabled={values.isSubmitting}
                        >
                          {/* {values ? (
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Submit"
                          )} */}
                          Save Changes
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycForm;
