import * as Yup from "yup";

export const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email("* Please enter a valid email")
    .required("* Email is required"),
});

export const resetPasswordSchema = Yup.object({
  uid: Yup.string().required("* uid is required"),
  token: Yup.string().required("* token is required"),
  new_password: Yup.string().required("* Password is required"),
  c_password: Yup.string().oneOf(
    [Yup.ref("new_password")],
    "* Your passwords do not match."
  ),
});

// cPassword: Yup.string()
//   .required("* Please Retype your password")
//   .min(8, "* Password must contain 8 or more characters")
//   .oneOf([Yup.ref("password")], "* Your passwords do not match."),
