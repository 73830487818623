import React from "react";
import { Icon } from "@iconify/react";
import styles from "./hoverSection.module.css";

const HomeSectionHover = (props) => {
  return (
    <div className={`${styles.hover_section_img_div}`}>
      <img
        className={styles.hover_section_img}
        src={props.imageUrl}
        alt="hover_section_img"
      />
      <div className={`${styles.hover_items}`}>
        <Icon icon={props.icon} className={`${styles.hover_icon}`} />
        <h4 className={`${styles.hover_title}`}>{props.name}</h4>
        <h3 className={`${styles.hover_rating}`}>{props.rating}</h3>
        <h4 className={`${styles.hover_address}`}>{props.address}</h4>
        <h3 className={`${styles.hover_price}`}>{props.price}</h3>
      </div>
    </div>
  );
};

export default HomeSectionHover;
