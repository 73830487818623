const monthName = [
  {
    id: 1,
    monthName: "January",
    value: "Jan",
  },
  {
    id: 2,
    monthName: "February",
    value: "Feb",
  },
  {
    id: 3,
    monthName: "March",
    value: "Mar",
  },
  {
    id: 4,
    monthName: "April",
    value: "Apr",
  },
  {
    id: 5,
    monthName: "May",
    value: "May",
  },
  {
    id: 6,
    monthName: "June",
    value: "Jun",
  },
  {
    id: 7,
    monthName: "July",
    value: "Jul",
  },
  {
    id: 8,
    monthName: "August",
    value: "Aug",
  },
  {
    id: 9,
    monthName: "September",
    value: "Sep",
  },
  {
    id: 10,
    monthName: "October",
    value: "Oct",
  },
  {
    id: 11,
    monthName: "November",
    value: "Nov",
  },
  {
    id: 12,
    monthName: "December",
    value: "Dec",
  },
];

export default monthName;
