import React from "react";
import styles from "./copyright.module.css";

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <div className={`container-fluid ${styles.copyright}`}>
        <div className="row">
          <div
            className={`col-md-4 tc footer-copyright ${styles.footer_copyright_text}`}
          >
            <p className="wc m-0">
              © {currentYear} Ghumfir. All Right reserved
            </p>
          </div>
          <div
            className={`col-md-1 tc footer-copyright ${styles.footer_copyright_text}`}
          >
            <p className="wc m-0">.Privacy</p>
          </div>
          <div
            className={`col-md-1 tc footer-copyright ${styles.footer_copyright_text}`}
          >
            <p className="wc m-0">.Terms</p>
          </div>
          <div
            className={`col-md-2 tc footer-copyright ${styles.footer_copyright_text}`}
          >
            <p className="wc m-0">.Cookie Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
