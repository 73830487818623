import { Icon } from "@iconify/react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./sidebar.module.css";

const Sidebar = () => {
  const pathname = useLocation().pathname;
  console.log("pathname", pathname);
  let activeStyle = {
    textDecoration: "none",
    color: "#FF770A",
    backgroundColor: "#E5E7EB",
    fontWeight: "bold",
  };

  let nonActiveStyle = {
    textDecoration: "none",
    // "&:hover": {
    //   color: "#FF770A ",
    // },
  };

  return (
    <div>
      <div className={`${styles.sidebar_container_fluid} container-fluid `}>
        <div className={`${styles.side_bar_row} row`}>
          <div className={`${styles.side_bar_col} col-lg-12 col-md-12`}>
            <div className={`${styles.side_bar_div}`}>
              <h3 className={`${styles.side_bar_title}`}>
                Accounts & Settings
              </h3>

              <ul className={`${styles.sidebar_list}`}>
                {pathname === "/account/recommend" ||
                pathname === "/account/match" ? (
                  <>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/recommend"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"bxs:user"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          Show Reccommended
                        </span>
                      </NavLink>
                    </li>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/match"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"bxs:user"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          Show Interested
                        </span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/my-profile"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"bxs:user"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          Personal Information
                        </span>
                      </NavLink>
                    </li>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/kyc-details"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"healthicons:health-worker-form-outline"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          KYC Form
                        </span>
                      </NavLink>
                    </li>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/my-interest"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"ic:round-interests"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          My Interest
                        </span>
                      </NavLink>
                    </li>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/timeline"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"clarity:timeline-line"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          Timeline
                        </span>
                      </NavLink>
                    </li>
                    <li className={`${styles.sidebar_list_items}`}>
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : nonActiveStyle
                        }
                        to="/account/security-settings"
                        className={`${styles.side_bar_link}`}
                      >
                        <Icon
                          icon={"ci:settings-filled"}
                          className={`${styles.side_bar_icon}`}
                        />
                        <span className={`${styles.side_bar_text}`}>
                          Security & Settings
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
